



















































































































































import Vue from "vue";
import {
  ApiConfiguration,
  FileExportType,
  LoadsClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { appendixName } from "@/scripts/misc/enumNames";
import DialogCard from "@/components/shared/ui/DialogCard.vue";

interface PackageSpecWrapper {
  type?: string;
  content?: PackageSpec[];
}

interface PackageSpec {
  type?: string;
  typeDesc?: string;
  huId?: string;

  id?: string;
  deliveryNo?: string;
  orderNo?: string;
  invoiceNo?: string;
  productNo?: string;
  productDesc?: string;
  packages?: string;
  weight?: string;
  volume?: string;
  qtyM?: string;

  length?: string;
  qty?: string;

  content?: PackageSpec[];
}

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    appendixId: Number
  },
  watch: {
    appendixId: {
      handler() {
        this.fetchAppendixData();
      },
      immediate: true
    }
  },
  computed: {
    unwrappedSpec(): PackageSpec[] {
      if (!this.data) {
        return [];
      }
      return this.unwrap(this.data.content);
    }
  },
  methods: {
    appendixName: appendixName,
    close() {
      this.$emit("close");
    },
    fetchAppendixData() {
      if (!this.appendixId) {
        this.data = undefined;
        return;
      }
      new LoadsClient(new ApiConfiguration(this.$store))
        .appendixData(this.appendixId)
        .then(res => {
          this.data = JSON.parse(res.json!);
        });
    },
    exportToExcel() {
      this.export(FileExportType.Excel);
    },
    exportToXML() {
      this.export(FileExportType.XML);
    },
    export(fileType: FileExportType) {
      this.exporting = true;
      new LoadsClient(new ApiConfiguration(this.$store))
        .appendixExport(this.appendixId, fileType)
        .then(res => {
          excelPrompt(res);
          this.exporting = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    unwrap(specs?: PackageSpec[]): PackageSpec[] {
      if (!specs) {
        return [];
      }
      return specs
        .map(s => [
          {
            typeDesc: s.typeDesc,
            huId: s.huId,
            packages: s.packages,
            weight: s.weight,
            volume: s.volume,
            id: s.id,
            deliveryNo: s.deliveryNo,
            orderNo: s.orderNo,
            invoiceNo: s.invoiceNo,
            productNo: s.productNo,
            productDesc: s.productDesc,
            qtyM: s.qtyM,
            length: s.length,
            qty: s.qty
          },
          ...this.unwrap(s.content)
        ])
        .reduce((res, cur) => [...res, ...cur]);
    }
  },
  data: (): {
    data?: PackageSpecWrapper | undefined;
    exporting: boolean;
  } => ({
    data: undefined,
    exporting: false
  })
});
