import {
  LoadSearchCriteriaDto,
  LoadStatus,
  CargoType,
  ClientAgreementSearchCriteria,
  SupplierAgreementSearchCriteria,
  EmailSearchCriteria,
  UserConfigurationType,
  LoadFilterDateType,
  ExplorerSearchCriteria,
  ActorType,
  CostReportSearchCriteria,
  SaveProcurementDto,
  AgreementPeriodDto,
  ProcurementPeriodDto,
  InvoiceReportSearchCriteria,
  DeviationReportSearchCriteria,
  DistanceReportSearchCriteria,
  LoadStatisticsReportSearchCriteria,
  InvoiceReportTreatedStatus,
  LoadStatisticsReportTab,
  LoadStatisticsReportSplit,
  AgreementStatisticsReportSearchCriteria,
  LoadVisibilityFilter,
  SupplierProcurementSearchCriteria,
  ProcurementAnalyticsSearchCriteria,
  AgreementRouteDistanceSearchCriteria,
  QualityPointsSearchCriteria,
  ClientMonitoringSearchCriteria,
  InvoiceSearchDto,
  InvoicedFeesSearchCriteria,
  QualityPointsReportSplit,
  HeatMapSearchCriteria
} from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import {
  AgreementRouteFilter,
  CalendarConfiguration,
  CreateLoadPresets,
  LoadListTab,
  MobilePopupConfiguration,
  ReadDocuments,
  spotTenderTabId,
  SupplierProcurementConfig
} from "@/scripts/types";
import { UserConfigurationEntry } from "@/scripts/store/userConfModule";
import neverHappens from "@/scripts/misc/neverHappens";
import {
  currencies,
  truckTypes,
  truckTypesByCargoType
} from "../misc/enumLists";

export default (
  type: UserConfigurationType,
  subTypeId: number,
  actorType: ActorType,
  json?: string
): UserConfigurationEntry => {
  let conf;
  switch (type) {
    case UserConfigurationType.ClientAgreement:
      conf = createClientAgreementSearchCriteria(subTypeId, json);
      break;
    case UserConfigurationType.ClientProcurement:
      conf = createClientAgreementSearchCriteria(subTypeId, json);
      break;
    case UserConfigurationType.SupplierAgreement:
      conf = createSupplierAgreementSearchCriteria(subTypeId, json);
      break;
    case UserConfigurationType.SupplierProcurement:
      conf = createSupplierProcurementSearchCriteria(subTypeId, json);
      break;
    case UserConfigurationType.LoadList:
      conf = createLoadSearchCriteria(subTypeId, json);
      break;
    case UserConfigurationType.LoadTab:
      conf = createLoadTabConf(subTypeId, actorType, json);
      break;
    case UserConfigurationType.CreateLoad:
      conf = createCreateLoadPresetsConf(json);
      break;
    case UserConfigurationType.ProcurementDraft:
      conf = createProcurementDraftConf(json);
      break;
    case UserConfigurationType.AgreementRoute:
      conf = createAgreementRouteConf(json);
      break;
    case UserConfigurationType.CostReport:
      conf = createCostReportSearchCriteria(json);
      break;
    case UserConfigurationType.InvoiceReport:
      conf = createInvoiceReportSearchCriteria(json);
      break;
    case UserConfigurationType.LoadStatisticsReport:
      conf = createLoadStatisticsReportSearchCriteria(json);
      break;
    case UserConfigurationType.AgreementStatisticsReport:
      conf = createAgreementStatisticsReportSearchCriteria(json);
      break;
    case UserConfigurationType.DeviationReport:
      conf = createDeviationReportSearchCriteria(json);
      break;
    case UserConfigurationType.DistanceReport:
      conf = createDistanceReportSearchCriteria(json);
      break;
    case UserConfigurationType.MobilePopup:
      conf = createMobilePopupConfiguration(json);
      break;
    case UserConfigurationType.SupplierProcurementConfig:
      conf = createSupplierProcurementConfiguration(json);
      break;
    case UserConfigurationType.CalendarConfiguration:
      conf = createCalendarConfiguration(json);
      break;
    case UserConfigurationType.ReadDocuments:
      conf = createReadDocuments(json);
      break;
    case UserConfigurationType.ProcurementAnalytics:
      conf = createProcurementAnalyticsSearchCriteria(json);
      break;
    case UserConfigurationType.QualityPoints:
      conf = createQualityPointsSearchCriteria(json);
      break;
    case UserConfigurationType.ClientMonitoring:
      conf = createClientMonitoringSearchCriteria(json);
      break;
    case UserConfigurationType.InvoicedFees:
      conf = createInvoicedFeesSearchCriteria(json);
      break;
    case UserConfigurationType.Mailviewer:
      conf = createMailviewerCriteria();
      break;
    case UserConfigurationType.Explorer:
      conf = createExplorerSearchCriteria();
      break;
    case UserConfigurationType.Distance:
      conf = createDistanceSearchCriteria();
      break;
    case UserConfigurationType.InvoiceGenerator:
      conf = createInvoiceGeneratorSearchCriteria();
      break;
    case UserConfigurationType.HeatMap:
      conf = createHeatMapSearchCriteria();
      break;
    default:
      neverHappens(type);
  }

  return {
    type: type,
    subTypeId: subTypeId,
    conf: conf
  } as UserConfigurationEntry;
};

function createClientAgreementSearchCriteria(
  cargoType: CargoType,
  json?: string
): ClientAgreementSearchCriteria {
  let searchCriteria = new ClientAgreementSearchCriteria({
    cargoType: cargoType,
    currencyType: undefined,
    truckType: undefined,
    supplierId: undefined,
    priceFilter: undefined,
    pickupCountry: null as any,
    deliveryCountry: null as any,
    pickupLocationCounty: null as any,
    deliveryLocationCounty: null as any,
    pickupLocationZipCode: null as any,
    deliveryLocationZipCode: null as any,
    tag: null as any,
    date: new MomentX(),
    unitBasePrice: false,
    unitBasePriceDate: mostRelevantBasePriceDate,
    baseIndexDate: undefined
  });
  try {
    const parsed = JSON.parse(json!);
    if (parsed.date !== undefined) {
      parsed.date = momentFromString(parsed.date);
    }
    if (parsed.unitBasePriceDate !== undefined) {
      parsed.unitBasePriceDate = momentFromString(parsed.unitBasePriceDate);
    }
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  searchCriteria.unitBasePrice = false;
  return searchCriteria;
}

function createSupplierAgreementSearchCriteria(
  cargoType: CargoType,
  json?: string
): SupplierAgreementSearchCriteria {
  let searchCriteria = new SupplierAgreementSearchCriteria({
    cargoType: cargoType,
    currencyType: undefined,
    truckType: undefined,
    clientId: undefined,
    pickupCountry: null as any,
    deliveryCountry: null as any,
    pickupLocationCounty: null as any,
    deliveryLocationCounty: null as any,
    pickupLocationZipCode: null as any,
    deliveryLocationZipCode: null as any,
    date: new MomentX(),
    unitBasePrice: false,
    unitBasePriceDate: mostRelevantBasePriceDate,
    baseIndexDate: undefined
  });
  try {
    const parsed = JSON.parse(json!);
    if (parsed.date !== undefined) {
      parsed.date = momentFromString(parsed.date);
    }
    if (parsed.unitBasePriceDate !== undefined) {
      parsed.unitBasePriceDate = momentFromString(parsed.unitBasePriceDate);
    }
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  searchCriteria.unitBasePrice = false;
  return searchCriteria;
}

function createSupplierProcurementSearchCriteria(
  cargoType: CargoType,
  json?: string
): SupplierProcurementSearchCriteria {
  let searchCriteria = new SupplierProcurementSearchCriteria({
    cargoType: cargoType,
    truckTypes: [],
    clientId: undefined,
    priceFilter: undefined,
    currency: undefined,
    daysForward: 30,
    pickupCountries: [],
    deliveryCountries: [],
    pickupCounty: null as any,
    deliveryCounty: null as any,
    pickupZipCode: null as any,
    deliveryZipCode: null as any,
    closedProcurements: false
  });
  try {
    const parsed = JSON.parse(json!);
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  return searchCriteria;
}

function createLoadSearchCriteria(
  subTypeId: number,
  json?: string
): LoadSearchCriteriaDto {
  let filterType: LoadFilterDateType;
  let daysBack: number;
  let daysForward: number;
  const statuses: LoadStatus[] = [subTypeId as LoadStatus];

  switch (subTypeId) {
    case LoadStatus.Ordered:
      filterType = LoadFilterDateType.RegDate;
      daysBack = 31;
      daysForward = -1;
      statuses.push(LoadStatus.MissingAgreement);
      break;
    case LoadStatus.Booked:
      filterType = LoadFilterDateType.BookedDate;
      daysBack = 31;
      daysForward = -1;
      break;
    case LoadStatus.Loaded:
      filterType = LoadFilterDateType.LoadedDate;
      daysBack = 31;
      daysForward = -1;
      break;
    case LoadStatus.Delivered:
      filterType = LoadFilterDateType.DeliverDate;
      daysBack = 31;
      daysForward = -1;
      break;
    case spotTenderTabId:
      filterType = LoadFilterDateType.SpotTenderEndDate;
      (daysBack = 370), (daysForward = -1);
      statuses.length = 0;
      statuses.push(LoadStatus.Ordered);
      break;
  }
  let searchCriteria = new LoadSearchCriteriaDto({
    loadId: undefined,
    transportNo: undefined,
    unit: undefined,
    referenceNo: undefined,
    regNo: undefined,
    statuses: statuses,
    supplierId: undefined,
    terminalId: undefined,
    customerId: undefined,
    truckType: [],
    hasExtraStops: undefined,
    resignedLoads: subTypeId !== LoadStatus.Ordered,
    loadVisibilityFilter:
      subTypeId === LoadStatus.Ordered
        ? LoadVisibilityFilter.OnlyPublished
        : subTypeId === spotTenderTabId
        ? LoadVisibilityFilter.OnlySpotTenders
        : LoadVisibilityFilter.NoFilter,
    pickupLocationCountryId: null as any,
    deliveryLocationCountryId: null as any,
    pickupLocationCounty: null as any,
    deliveryLocationCounty: null as any,
    pickupLocationZipCode: null as any,
    deliveryLocationZipCode: null as any,

    filterDateType: filterType!,
    filterDateDaysBack: daysBack!,
    filterDateDaysForward: daysForward!
  });
  try {
    const parsed = JSON.parse(json!);
    if (parsed.filterDate !== undefined) {
      parsed.filterDate = momentFromString(parsed.filterDate);
    }
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  return searchCriteria;
}

function createLoadTabConf(
  subTypeId: number,
  actorType: ActorType,
  json?: string
): LoadListTab {
  let conf = new LoadListTab(subTypeId, actorType);
  try {
    const parsed = JSON.parse(json!);
    conf = Object.assign(conf, parsed);
  } catch (error) {}
  return conf;
}

function createCreateLoadPresetsConf(json?: string): CreateLoadPresets {
  let conf = new CreateLoadPresets("", "");
  try {
    const parsed = JSON.parse(json!);
    conf = Object.assign(conf, parsed);
  } catch (error) {}
  return conf;
}

function createProcurementDraftConf(json?: string): SaveProcurementDto {
  let saveDto = new SaveProcurementDto({
    cargoType: undefined as any,
    message: "",
    documentGroupId: undefined as any,
    agreementRouteIds: [],
    supplierIds: [],
    agreementPeriod: new AgreementPeriodDto({
      from: undefined as any,
      to: undefined as any
    }),
    procurementPeriods: [
      new ProcurementPeriodDto({
        number: 1,
        from: undefined as any,
        to: undefined as any,
        rankingMethod: undefined as any,
        qualityPointsFrom: undefined as any,
        qualityPointsTo: undefined as any
      })
    ]
  });
  try {
    const parsed: SaveProcurementDto = JSON.parse(json!);
    saveDto = new SaveProcurementDto({
      cargoType: parsed.cargoType,
      message: parsed.message,
      documentGroupId: parsed.documentGroupId,
      agreementRouteIds: parsed.agreementRouteIds,
      supplierIds: parsed.supplierIds,
      agreementPeriod: new AgreementPeriodDto({
        from: momentFromString(parsed.agreementPeriod!.from as any),
        to: momentFromString(parsed.agreementPeriod!.to as any)
      }),
      procurementPeriods: parsed.procurementPeriods!.map(
        p =>
          new ProcurementPeriodDto({
            number: p.number,
            from: momentFromString(p.from as any),
            to: momentFromString(p.to as any),
            rankingMethod: p.rankingMethod,
            qualityPointsFrom: momentFromString(p.qualityPointsFrom as any),
            qualityPointsTo: momentFromString(p.qualityPointsTo as any)
          })
      )
    });
  } catch (error) {}
  return saveDto;
}

function createAgreementRouteConf(json?: string): AgreementRouteFilter {
  let filter: AgreementRouteFilter = {
    cargoType: CargoType.Road,
    routeId: "",
    pickupCounty: "",
    pickupZipcode: "",
    pickupCountry: undefined,
    deliveryCounty: "",
    deliveryZipcode: "",
    deliveryCountry: undefined,
    currency: undefined,
    estimate: undefined,
    specReq: "",
    tag: undefined,
    procurement: undefined,
    agreementEnd: undefined
  };
  try {
    const parsed: AgreementRouteFilter = JSON.parse(json!);
    filter = Object.assign(filter, parsed);
    filter.agreementEnd = undefined; //Blir inge bra att spara denna
  } catch (error) {}
  return filter;
}

/******************************************** REPORTS *****************************************/

function createCostReportSearchCriteria(
  json?: string
): CostReportSearchCriteria {
  let searchCriteria = new CostReportSearchCriteria({
    cargoType: CargoType.Road,
    currencyType: undefined,
    truckType: undefined,
    pickupCountry: undefined,
    deliveryCountry: undefined,
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    unit: undefined,
    fromDate: new MomentX(),
    toDate: new MomentX()
  });
  try {
    const parsed = JSON.parse(json!);
    parsed.fromDate = new MomentX();
    parsed.toDate = new MomentX();
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  //Vi skriver över datumet som kom från db.
  searchCriteria.fromDate = new MomentX().startOfMonth();
  searchCriteria.toDate = new MomentX().endOfMonth();
  return searchCriteria;
}

function createInvoiceReportSearchCriteria(
  json?: string
): InvoiceReportSearchCriteria {
  let searchCriteria = new InvoiceReportSearchCriteria({
    cargoType: CargoType.Road,
    fromDate: new MomentX(),
    toDate: new MomentX(),
    actorId: undefined,
    pickupCountry: undefined,
    deliveryCountry: undefined,
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    unit: undefined,
    currency: undefined,
    transportNo: undefined,
    treatedStatuses: [
      InvoiceReportTreatedStatus.Treated,
      InvoiceReportTreatedStatus.Untreated
    ]
  });
  try {
    const parsed = JSON.parse(json!);
    parsed.fromDate = new MomentX();
    parsed.toDate = new MomentX();
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  //Vi skriver över datumet som kom från db.
  searchCriteria.fromDate = new MomentX().startOfMonth();
  searchCriteria.toDate = new MomentX().endOfMonth();
  return searchCriteria;
}

function createLoadStatisticsReportSearchCriteria(
  json?: string
): LoadStatisticsReportSearchCriteria {
  let searchCriteria = new LoadStatisticsReportSearchCriteria({
    cargoType: CargoType.Road,
    fromDate: new MomentX(),
    toDate: new MomentX(),
    actorId: undefined,
    unit: undefined,
    truckTypes: [],
    pickupCountry: undefined,
    deliveryCountries: [],
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    includeBroadcast: true,
    splitColumns: [LoadStatisticsReportSplit.Actors],
    tab: LoadStatisticsReportTab.Bookings
  });
  try {
    const parsed = JSON.parse(json!);
    parsed.fromDate = new MomentX();
    parsed.toDate = new MomentX();
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  //Vi skriver över datumet som kom från db.
  searchCriteria.fromDate = new MomentX().startOfMonth();
  searchCriteria.toDate = new MomentX().endOfMonth();
  return searchCriteria;
}

function createAgreementStatisticsReportSearchCriteria(
  json?: string
): AgreementStatisticsReportSearchCriteria {
  let searchCriteria = new AgreementStatisticsReportSearchCriteria({
    cargoType: CargoType.Road,
    fromDate: new MomentX(),
    toDate: new MomentX(),
    actorId: undefined,
    truckTypes: [],
    pickupCountry: undefined,
    deliveryCountries: [],
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    includeBroadcast: true
  });
  try {
    const parsed = JSON.parse(json!);
    parsed.fromDate = new MomentX();
    parsed.toDate = new MomentX();
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  //Vi skriver över datumet som kom från db.
  searchCriteria.fromDate = new MomentX().startOfMonth();
  searchCriteria.toDate = new MomentX().endOfMonth();
  return searchCriteria;
}

function createDeviationReportSearchCriteria(
  json?: string
): DeviationReportSearchCriteria {
  let searchCriteria = new DeviationReportSearchCriteria({
    cargoType: CargoType.Road,
    fromDate: new MomentX(),
    toDate: new MomentX(),
    supplierId: undefined,
    pickupCountry: undefined,
    deliveryCountries: [],
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    deviationCauses: [],
    otherPriceCauses: []
  });
  try {
    const parsed = JSON.parse(json!);
    parsed.fromDate = new MomentX();
    parsed.toDate = new MomentX();
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  //Vi skriver över datumet som kom från db.
  searchCriteria.fromDate = new MomentX().startOfMonth();
  searchCriteria.toDate = new MomentX().endOfMonth();
  return searchCriteria;
}

function createDistanceReportSearchCriteria(
  json?: string
): DistanceReportSearchCriteria {
  let searchCriteria = new DistanceReportSearchCriteria({
    cargoType: CargoType.Road,
    truckType: undefined,
    fromDate: new MomentX(),
    toDate: new MomentX(),
    pickupCountry: undefined,
    deliveryCountry: undefined,
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    mergeTruckTypes: false
  });
  try {
    const parsed = JSON.parse(json!);
    parsed.fromDate = new MomentX();
    parsed.toDate = new MomentX();
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  //Vi skriver över datumet som kom från db.
  searchCriteria.fromDate = new MomentX().startOfMonth();
  searchCriteria.toDate = new MomentX().endOfMonth();
  return searchCriteria;
}

function createProcurementAnalyticsSearchCriteria(
  json?: string
): ProcurementAnalyticsSearchCriteria {
  let searchCriteria = new ProcurementAnalyticsSearchCriteria({
    cargoType: CargoType.Road,
    truckType: undefined,
    agreementValidDate: new MomentX(),
    unitBasePriceDate: mostRelevantBasePriceDate,
    supplierId: undefined,
    pickupCountry: undefined,
    deliveryCountry: undefined,
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    tag: undefined,
    currency: undefined
  });
  try {
    const parsed = JSON.parse(json!);
    if (parsed.unitBasePriceDate !== undefined) {
      parsed.unitBasePriceDate = momentFromString(parsed.unitBasePriceDate);
    }
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  searchCriteria.agreementValidDate = new MomentX().startOfDay();
  return searchCriteria;
}

function createQualityPointsSearchCriteria(
  json?: string
): QualityPointsSearchCriteria {
  let searchCriteria = new QualityPointsSearchCriteria({
    cargoType: CargoType.Road,
    truckType: undefined,
    fromDate: new MomentX(),
    toDate: new MomentX(),
    pickupCountry: undefined,
    deliveryCountry: undefined,
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    actorId: undefined,
    unit: undefined,
    split: QualityPointsReportSplit.Route
  });
  try {
    const parsed = JSON.parse(json!);
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  searchCriteria.fromDate = new MomentX().addYears(-1).startOfDay();
  searchCriteria.toDate = new MomentX().startOfDay();
  return searchCriteria;
}

function createClientMonitoringSearchCriteria(
  json?: string
): ClientMonitoringSearchCriteria {
  let searchCriteria = new ClientMonitoringSearchCriteria({
    cargoType: CargoType.Road,
    truckType: undefined,
    fromDate: new MomentX(),
    toDate: new MomentX(),
    pickupCountry: undefined,
    deliveryCountry: undefined,
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    unit: undefined
  });
  try {
    const parsed = JSON.parse(json!);
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  searchCriteria.fromDate = new MomentX().addMonths(-1).startOfDay();
  searchCriteria.toDate = new MomentX().startOfDay();
  return searchCriteria;
}

function createInvoicedFeesSearchCriteria(
  json?: string
): InvoicedFeesSearchCriteria {
  let searchCriteria = new InvoicedFeesSearchCriteria({
    actorId: undefined as any,
    invoiceDate: undefined as any,
    loadId: undefined,
    transportNo: undefined,
    pickupCountry: undefined,
    pickupCounty: undefined,
    pickupZipCode: undefined,
    deliveryCountry: undefined,
    deliveryCounty: undefined,
    deliveryZipCode: undefined,
    unit: undefined,
    feeType: undefined,
    currency: undefined
  });
  try {
    const parsed = JSON.parse(json!);
    if (parsed.invoiceDate !== undefined) {
      parsed.invoiceDate = momentFromString(parsed.invoiceDate);
    }
    searchCriteria = Object.assign(searchCriteria, parsed);
  } catch (error) {}
  return searchCriteria;
}

function createMobilePopupConfiguration(
  json?: string
): MobilePopupConfiguration {
  let conf = new MobilePopupConfiguration({
    dismissedDates: []
  });
  try {
    const parsed = JSON.parse(json!);
    if (parsed.dismissedDates) {
      parsed.dismissedDates = parsed.dismissedDates.map((d: string) =>
        momentFromString(d)
      );
    }
    conf = Object.assign(conf, parsed);
  } catch (error) {}
  return conf;
}

function createCalendarConfiguration(json?: string): CalendarConfiguration {
  let conf = new CalendarConfiguration({
    calendarAutoDateSliding: true
  });
  try {
    const parsed = JSON.parse(json!);
    conf = Object.assign(conf, parsed);
  } catch (error) {}
  return conf;
}

function createReadDocuments(json?: string): ReadDocuments {
  let conf = new ReadDocuments({
    agreements: []
  });
  try {
    const parsed = JSON.parse(json!);
    conf = Object.assign(conf, parsed);
  } catch (error) {}
  return conf;
}

function createSupplierProcurementConfiguration(
  json?: string
): SupplierProcurementConfig {
  let conf = new SupplierProcurementConfig({
    kmPrices: truckTypes()
      .filter(tt =>
        truckTypesByCargoType(CargoType.Road).some(t => t.id === tt.id)
      )
      .flatMap(tt =>
        currencies().map(c => ({
          truckType: tt.id,
          currency: c.id,
          value: undefined
        }))
      ),
    stopPrices: currencies().map(c => ({ currency: c.id, value: undefined })),
    truckPercentage: 45,
    wagonPercentage: 70
  });
  try {
    const parsed = JSON.parse(json!);
    conf = Object.assign(conf, parsed);
  } catch (error) {}
  return conf;
}

/******************************************** C-ADMIN *****************************************/

function createMailviewerCriteria() {
  return new EmailSearchCriteria({
    topMailCount: 100,
    emailAddressFilter: "",
    fromDate: new MomentX("2010-01-01"),
    toDate: new MomentX("2030-01-01"),
    withRetries: undefined
  });
}

function createExplorerSearchCriteria(): ExplorerSearchCriteria {
  return new ExplorerSearchCriteria({
    method: 1,
    text: "",
    loadStatuses: [],
    client: undefined,
    fromDate: new MomentX("2010-01-01"),
    toDate: new MomentX("2030-01-01")
  });
}

function createDistanceSearchCriteria(): AgreementRouteDistanceSearchCriteria {
  return new AgreementRouteDistanceSearchCriteria({
    clientId: undefined,
    cargoType: undefined,
    hasDistance: undefined,
    hidden: undefined,
    pickupCountry: undefined,
    deliveryCountry: undefined,
    pickupCounty: undefined,
    deliveryCounty: undefined,
    pickupZipCode: undefined,
    deliveryZipCode: undefined,
    routingExactnessPickup: undefined,
    routingExactnessDelivery: undefined
  });
}

function createInvoiceGeneratorSearchCriteria(): InvoiceSearchDto {
  return new InvoiceSearchDto({
    date: new MomentX().startOfMonth(),
    feeTypes: []
  });
}

function createHeatMapSearchCriteria(): HeatMapSearchCriteria {
  return new HeatMapSearchCriteria({
    fromDate: new MomentX()
      .startOfDay()
      .addDays(1)
      .addYears(-1),
    toDate: new MomentX().startOfDay().addDays(1),
    hoursPerGroup: 1,
    clientId: 1,
    units: []
  });
}

const mostRelevantBasePriceDate = new MomentX("2021-12-10");

export const momentFromString = (str: string | undefined | null): MomentX => {
  if (!str) {
    return undefined as any;
  }
  if (str.includes("T")) {
    str = str.substring(0, str.indexOf("T"));
  }
  return new MomentX(str);
};
