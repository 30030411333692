



































































































































































import Vue from "vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import DistanceInput from "@/components/shared/input/DistanceInput.vue";
import {
  ActorConfigurationType,
  AgreementRoutesClient,
  ApiConfiguration,
  CargoType,
  Country,
  CountyWithZipcodeDto,
  CreateAgreementRouteDto,
  Currency,
  RepositoryDto,
  TruckType,
  UsersClient
} from "@/scripts/cld.api";
import cached from "@/scripts/misc/cached";
import { cargoTypeName, currencyName } from "@/scripts/misc/enumNames";
import { actions, gets, mutations } from "@/scripts/store/constants";
import { alertDialog, popupDialog } from "@/scripts/misc/popupDialogs";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import { t } from "@/scripts/language/i18n";
import {
  actorCargoTypes,
  currencies,
  truckTypesByCargoType
} from "@/scripts/misc/enumLists";
import { ActorConfigurationEntry } from "@/scripts/store/actorConfModule";
import { pushNotification } from "@/scripts/misc/notifications";
import { userInfoFromProfileDto } from "@/scripts/types";
export default Vue.extend({
  props: {
    dialog: Boolean,
    cargoType: Number as () => CargoType
  },
  components: {
    DialogCard,
    GreyTitle,
    Dropdown,
    TextMulti,
    TextSingle,
    Autocomplete,
    DistanceInput,
    NumberInput
  },
  watch: {
    pickupCountry: {
      handler() {
        this.fetchPickupLocations();
      },
      immediate: true
    },
    deliveryCountry: {
      handler() {
        this.fetchDeliveryLocations();
      },
      immediate: true
    }
  },
  computed: {
    title(): String {
      return (
        t("CreateNewAgreementRoute") +
        " (" +
        cargoTypeName(this.cargoType) +
        ")"
      );
    },
    pickupLocation(): CountyWithZipcodeDto {
      const location = this.pickupLocations.find(
        l => l.id === this.saveDto!.pickupLocationId
      );
      if (location) {
        return location;
      }
      return new CountyWithZipcodeDto({ id: 0, county: "", zipcode: "" });
    },
    deliveryLocation(): CountyWithZipcodeDto {
      const location = this.deliveryLocations.find(
        l => l.id === this.saveDto!.deliveryLocationId
      );
      if (location) {
        return location;
      }
      return new CountyWithZipcodeDto({ id: 0, county: "", zipcode: "" });
    },
    saveEnabled() {
      if (
        this.saveDto!.currency &&
        this.saveDto!.pickupLocationId &&
        this.saveDto!.deliveryLocationId
      ) {
        return true;
      }
      return false;
    },
    conf(): RepositoryDto[] {
      return this.$store.getters[gets.actorConf](
        ActorConfigurationType.ZipcodeByCounty,
        1
      );
    }
  },
  methods: {
    init() {
      this.resetDialog();
      this.truckTypes = truckTypesByCargoType(this.cargoType);
      this.fetchCountries();
    },
    resetDialog() {
      this.deliveryCounty = undefined;
      this.deliveryZipcodes = [];
      this.saveDto = new CreateAgreementRouteDto({
        cargoType: this.cargoType,
        truckTypes: truckTypesByCargoType(this.cargoType).map(
          tt => tt.id as TruckType
        ),
        pickupLocationId: !this.saveDto
          ? (undefined as any)
          : this.saveDto.pickupLocationId,
        deliveryLocationId: undefined as any,
        currency: undefined as any,
        estimate: undefined,
        specialRequirements: undefined,
        distance: undefined,
        tag: undefined as any,
        customsInfoRequired: false
      });
    },
    closeDialog() {
      this.$emit("close");
    },
    saveActorConf() {
      this.$store.dispatch(actions.saveActorConf, {
        type: ActorConfigurationType.ZipcodeByCounty,
        subTypeId: 1
      });
    },
    setActorConf() {
      const entries = [
        new RepositoryDto({
          id: this.saveDto!.pickupLocationId,
          text: this.pickupCounty
        }),
        new RepositoryDto({
          id: this.saveDto!.deliveryLocationId,
          text: this.deliveryCounty
        })
      ];
      this.$store.commit(mutations.setActorConf, {
        type: ActorConfigurationType.ZipcodeByCounty,
        subTypeId: 1,
        conf: [
          ...this.conf.filter(c => !entries.some(e => e.text === c.text)),
          ...entries
        ]
      } as ActorConfigurationEntry);
    },
    populatePickupZipcodes() {
      const zipcodeByCounty = this.conf.find(x => x.text === this.pickupCounty);
      this.saveDto!.pickupLocationId = zipcodeByCounty
        ? zipcodeByCounty.id
        : (undefined as any);
      this.pickupZipcodes = this.pickupLocations
        .filter(l => l.county === this.pickupCounty)
        .map(l => new RepositoryDto({ id: l.id, text: l.zipcode }));
    },
    populateDeliveryZipcodes() {
      this.saveDto!.deliveryLocationId = undefined as any; //Autoselectar inte postkod på leveransort
      this.deliveryZipcodes = this.deliveryLocations
        .filter(l => l.county === this.deliveryCounty)
        .map(l => new RepositoryDto({ id: l.id, text: l.zipcode }));
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPickupLocations() {
      if (!this.pickupCountry) {
        return;
      }
      this.pickupCounties = [];
      this.pickupLocations = [];
      cached("countiesAndZipcode", this.pickupCountry)
        .then(res => {
          this.pickupLocations = res;
          this.pickupCounties = [
            ...new Map(
              res.map(x => [
                x.county,
                new RepositoryDto({ id: x.county as any, text: x.county })
              ])
            ).values()
          ];
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchDeliveryLocations() {
      if (!this.deliveryCountry) {
        return;
      }
      this.deliveryCounties = [];
      this.deliveryLocations = [];
      cached("countiesAndZipcode", this.deliveryCountry)
        .then(res => {
          this.deliveryLocations = res;
          this.deliveryCounties = [
            ...new Map(
              res.map(x => [
                x.county,
                new RepositoryDto({ id: x.county as any, text: x.county })
              ])
            ).values()
          ];
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    startSave() {
      this.saveInProgress = true;
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .agreementRouteExists(this.saveDto!)
        .then(res => {
          this.handleSave(res);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    handleSave(currencyOfCopy: Currency | null) {
      if (!currencyOfCopy) {
        this.saveAgreementRoute();
      } else if (currencyOfCopy === this.saveDto!.currency) {
        alertDialog(
          this.$t("TheOperationWasCancelled").toString(),
          this.$t("BLE_AgreementRouteAlreadyExists").toString()
        );
        this.saveInProgress = false;
      } else {
        popupDialog({
          title: this.$t("BLE_AgreementRouteAlreadyExists").toString(),
          body:
            this.$t("AgreementRouteExistsWithDifferentCurrency").toString() +
            " " +
            currencyName(currencyOfCopy) +
            ", " +
            this.$t("DoYouWantToSaveAndHide"),
          btnText1: this.$t("HideAndSave").toString(),
          btnColor1: "success",
          btnCallback1: () => {
            this.saveAgreementRoute();
          },
          btnText2: this.$t("Cancel").toString(),
          btnColor2: "warning",
          btnCallback2: () => {
            this.saveInProgress = false;
          }
        });
      }
    },
    saveAgreementRoute() {
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .createAgreementRoute(this.saveDto!)
        .then(() => {
          this.saveInProgress = false;
          this.$store.commit(mutations.setLocationCacheOutdated, true);
          this.setActorConf();
          this.saveActorConf();
          this.resetDialog();
          pushNotification(
            t("AgreementRouteCreated") + "!",
            "mdi-check-circle"
          );
          if (!actorCargoTypes().some(c => c.id === this.saveDto!.cargoType)) {
            new UsersClient(new ApiConfiguration(this.$store))
              .profile()
              .then(profile => {
                this.$store.commit(
                  mutations.setUserInfo,
                  userInfoFromProfileDto(profile)
                );
              })
              .catch(error => {
                this.$store.dispatch(actions.handleApiError, error);
              });
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    truckTypes: RepositoryDto[];
    currencies: RepositoryDto[];
    countries: RepositoryDto[];
    pickupCounties: RepositoryDto[];
    pickupZipcodes: RepositoryDto[];
    pickupLocations: CountyWithZipcodeDto[];
    deliveryCounties: RepositoryDto[];
    deliveryZipcodes: RepositoryDto[];
    deliveryLocations: CountyWithZipcodeDto[];
    pickupCounty?: string;
    pickupCountry?: Country;
    deliveryCounty?: string;
    deliveryCountry?: Country;
    saveDto?: CreateAgreementRouteDto;
    saveInProgress: boolean;
  } => ({
    truckTypes: [],
    currencies: currencies(),
    countries: [],
    pickupCounties: [],
    pickupZipcodes: [],
    pickupLocations: [],
    deliveryCounties: [],
    deliveryZipcodes: [],
    deliveryLocations: [],
    pickupCounty: undefined,
    pickupCountry: undefined,
    deliveryCounty: undefined,
    deliveryCountry: undefined,
    saveDto: undefined,
    saveInProgress: false
  })
});
