































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";

export default Vue.extend({
  components: {
    DialogCard,
    TextMulti
  },
  props: {
    dialog: Boolean
  },
  methods: {
    update() {
      this.$emit("update", this.spec);
      this.close();
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    spec: string | null;
  } => ({
    spec: null
  })
});
