import { ConfirmationOptions } from "@/scripts/types";
import { t } from "@/scripts/language/i18n";
import { mutations } from "@/scripts/store/constants";
import store from "@/scripts/store/store";

export const errorDialog = (errorObject: any, callback: Function) => {
  const title = t("TheOperationWasCancelled");
  let body;
  const lines = Object.entries(errorObject.response.errors)[0];
  if (lines && lines.length > 0) {
    body = lines[0];
    if (body.startsWith("$")) {
      //Means we should translate it
      body = t(body.substr(1));
    }
    if (lines[1]) {
      for (const line of lines[1] as string[]) {
        body += `\n${line}`;
      }
    }
  } else {
    body = "An unhandled exception has occurred.";
  }
  const options: ConfirmationOptions = {
    width: 600,
    noCloseButton: true,
    warning: true,
    title: title,
    body: body,
    btnText1: "OK",
    btnCallback1: () => {
      callback();
    }
  };
  popupDialog(options);
};

export const alertDialog = (title: string, body: string, width?: number) => {
  const options: ConfirmationOptions = {
    width: width,
    noCloseButton: true,
    warning: true,
    title: title,
    body: body,
    btnText1: "OK",
    btnCallback1: () => {}
  };
  popupDialog(options);
};

export const saveBeforeContinueDialog = (
  saveCallback: Function,
  dontSaveCallback: Function
) => {
  const options: ConfirmationOptions = {
    title: t("UnsavedChanges"),
    body: t("SaveBeforeExit"),
    btnText1: t("Save"),
    btnColor1: "success",
    btnCallback1: saveCallback,
    btnText2: t("DontSave"),
    btnColor2: "error",
    btnCallback2: dontSaveCallback
  };
  popupDialog(options);
};

export const discardChangesDialog = (callback: Function) => {
  const options: ConfirmationOptions = {
    title: t("UnsavedChanges"),
    body: t("YouHaveUnsavedChanges"),
    btnText1: t("Continue"),
    btnColor1: "error",
    btnCallback1: callback
  };
  popupDialog(options);
};

export const popupDialog = (options: ConfirmationOptions) => {
  store.commit(mutations.setConfirmationOptions, options);
};
