























































































































































































































































































































import Vue from "vue";
import {
  AgreementRouteFilter,
  TableHeader,
  TableOptions
} from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import {
  AgreementRouteEditTracker,
  RouteTab
} from "@/views/client/AgreementRoutes.vue";
import { currencyName, isoName } from "@/scripts/misc/enumNames";
import {
  AgreementRouteDto,
  AgreementRoutesClient,
  ApiConfiguration,
  CargoType,
  EstimateDto,
  EstimateFromHistoricalPeriodSearchCriteria,
  TruckType,
  UserConfigurationType
} from "@/scripts/cld.api";
import { stringCompare, stringSearchHit } from "@/scripts/misc/stringUtils";
import AgreementRouteTableTruckTypeItems from "@/components/web/agreements/client/AgreementRouteTableTruckTypeItems.vue";
import AgreementRouteTableTruckTypeHeader from "@/components/web/agreements/client/AgreementRouteTableTruckTypeHeader.vue";
import AgreementRouteEstimateHistoryDialog from "@/components/web/agreements/client/AgreementRouteEstimateHistoryDialog.vue";
import AgreementRouteTagDialog from "@/components/web/agreements/client/AgreementRouteTagDialog.vue";
import AgreementRouteSpecReqDialog from "@/components/web/agreements/client/AgreementRouteSpecReqDialog.vue";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import MomentX from "@/scripts/misc/momentX";
import { actions, gets } from "@/scripts/store/constants";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { t } from "@/scripts/language/i18n";
import { pushNotification } from "@/scripts/misc/notifications";
import { popupDialog } from "@/scripts/misc/popupDialogs";
import { truckTypes } from "@/scripts/misc/enumLists";

export default Vue.extend({
  props: {
    loading: Boolean,
    routes: Array as () => AgreementRouteDto[],
    tracker: Object as () => AgreementRouteEditTracker
  },
  components: {
    CustomTableHeader,
    CustomFilter,
    InfoTooltip,
    TextSingle,
    TextMulti,
    NumberInput,
    EllipsisTableItem,
    AgreementRouteTableTruckTypeItems,
    AgreementRouteTableTruckTypeHeader,
    AgreementRouteEstimateHistoryDialog,
    AgreementRouteTagDialog,
    AgreementRouteSpecReqDialog,
    CheckboxTiny
  },
  watch: {
    sortedRoutes: {
      handler() {
        this.deleteInProgress = 0;
        if (!this.tracker.editingTab) {
          const start =
            (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
          const end = start + this.tableOptions.itemsPerPage;
          this.tracker.editingRoutes = this.sortedRoutes
            .slice(start, end)
            .map(r => ({
              id: r.id,
              edited: false,
              originalValue: this.valueForChangeDetection(r)
            }));
        }
      },
      immediate: true,
      deep: true
    },
    loading() {
      if (this.loading) {
        this.tableOptions.page = 1;
      }
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 353);
    },
    tagTabSelected(): boolean {
      return this.tracker.tabId === RouteTab.Tag;
    },
    estimateEditable(): boolean {
      return this.tracker.tabId === RouteTab.Estimate;
    },
    filter(): AgreementRouteFilter {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.AgreementRoute,
        1
      );
    },
    filteredHeaders(): TableHeader[] {
      const headers = [
        ...this.baseHeaders,
        ...this.extraHeaders.filter(h => h.id === this.tracker.tabId)
      ];
      headers
        .filter(h => h.value === "estimatedNoOfLoads")
        .map(
          h =>
            (h.text = `${t("Estimation")} (${
              this.filter.cargoType === CargoType.Container
                ? t("Container")
                : t("LoadsWithUppercaseL")
            })`)
        );
      return headers;
    },
    filteredRoutes(): AgreementRouteDto[] {
      if (this.tracker.editingTab) {
        return this.routes.filter(r =>
          this.tracker.editingRoutes.map(e => e.id).includes(r.id)
        );
      }
      return this.routes
        .filter(
          r =>
            !this.tracker.editingTab ||
            this.tracker.editingRoutes.some(e => e.id === r.id)
        )
        .filter(r => this.tracker.tabId === RouteTab.Visibility || !r.hidden)
        .filter(
          r =>
            !this.filter.routeId ||
            stringSearchHit(this.filter.routeId, r.routeId.toString(), true)
        )
        .filter(
          r =>
            !this.filter.pickupCounty ||
            r.pickupCounty === this.filter.pickupCounty
        )
        .filter(
          r =>
            !this.filter.deliveryCounty ||
            r.deliveryCounty === this.filter.deliveryCounty
        )
        .filter(
          r =>
            !this.filter.pickupZipcode ||
            stringSearchHit(this.filter.pickupZipcode, r.pickupZipcode, true)
        )
        .filter(
          r =>
            !this.filter.deliveryZipcode ||
            stringSearchHit(
              this.filter.deliveryZipcode,
              r.deliveryZipcode,
              true
            )
        )
        .filter(
          r =>
            !this.filter.pickupCountry ||
            r.pickupCountry === this.filter.pickupCountry
        )
        .filter(
          r =>
            !this.filter.deliveryCountry ||
            r.deliveryCountry === this.filter.deliveryCountry
        )
        .filter(
          r => !this.filter.currency || r.currency === this.filter.currency
        )
        .filter(
          r =>
            (this.filter.estimate !== true && !r.estimatedNoOfLoads) ||
            (this.filter.estimate !== false && r.estimatedNoOfLoads)
        )
        .filter(
          r =>
            !this.filter.specReq ||
            stringSearchHit(this.filter.specReq, r.specialRequirements, false)
        )
        .filter(
          r =>
            !this.filter.tag ||
            (this.filter.tag === "null" && !r.tag) ||
            this.filter.tag === r.tag
        )
        .filter(
          r =>
            (this.filter.procurement !== true &&
              this.filter.procurement !== false) ||
            (this.filter.procurement === true &&
              r.procurementPeriods!.length) ||
            (this.filter.procurement === false && !r.procurementPeriods!.length)
        )
        .filter(
          r =>
            this.filter.agreementEnd === undefined ||
            (this.filter.agreementEnd === 0 &&
              r.agreementPeriods!.length === 0) ||
            r
              .agreementPeriods!.map(p => p.to.startOfDay().unix())
              .includes(this.filter.agreementEnd)
        );
    },
    sortedRoutes(): AgreementRouteDto[] {
      let res = [...this.filteredRoutes];
      let c: { (a: AgreementRouteDto, b: AgreementRouteDto): number };
      const sortBy = this.tableOptions.sortBy[0];
      const sortDesc = this.tableOptions.sortDesc[0];
      switch (sortBy) {
        case "pickup":
          c = (a, b) => stringCompare(this.pickup(a), this.pickup(b));
          break;
        case "delivery":
          c = (a, b) => stringCompare(this.delivery(a), this.delivery(b));
          break;
        case "procurementPeriods":
          c = (a, b) =>
            stringCompare(
              this.procurementPeriods(a),
              this.procurementPeriods(b)
            );
          break;
        case "agreementPeriods":
          c = (a, b) =>
            stringCompare(this.agreementPeriods(a), this.agreementPeriods(b));
          break;
        default:
          c = (a, b) => stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      }
      res = res.sort(
        (a, b) =>
          (sortDesc ? -1 : 1) * c(a, b) ||
          stringCompare(this.pickup(a), this.pickup(b)) ||
          stringCompare(this.delivery(a), this.delivery(b))
      );
      return res;
    },
    allHidden(): boolean | undefined {
      const routes = this.sortedRoutes.filter(r =>
        this.tracker.editingRoutes.some(e => e.id === r.id)
      );
      if (routes.every(r => r.hidden)) {
        return true;
      }
      if (routes.some(r => r.hidden)) {
        return undefined;
      }
      return false;
    },
    allCustomsInfoRequired(): boolean | undefined {
      const routes = this.sortedRoutes.filter(r =>
        this.tracker.editingRoutes.some(e => e.id === r.id)
      );
      if (routes.every(r => r.customsInfoRequired)) {
        return true;
      }
      if (routes.some(r => r.customsInfoRequired)) {
        return undefined;
      }
      return false;
    },
    truckTypesForHeader(): TruckType[] {
      return truckTypes()
        .map(tt => tt.id as TruckType)
        .filter(tt => this.sortedRoutes.every(r => r.truckTypes!.includes(tt)));
    },
    indeterminatesForHeader(): TruckType[] {
      return truckTypes()
        .map(tt => tt.id as TruckType)
        .filter(tt => !this.truckTypesForHeader.includes(tt))
        .filter(tt => this.sortedRoutes.some(r => r.truckTypes!.includes(tt)));
    }
  },
  methods: {
    currencyName: currencyName,
    agreementPeriods(route: AgreementRouteDto, includeAll?: boolean): string {
      return route
        .agreementPeriods!.filter((_, index) => includeAll || index === 0)
        .map(p => `${p.from.datePrint()} - ${p.to.datePrint()}`)
        .join("\n");
    },
    procurementPeriods(route: AgreementRouteDto, includeAll?: boolean): string {
      const dates = route
        .procurementPeriods!.filter(
          p => includeAll || p.to.isSameOrAfter(new MomentX().startOfDay())
        )
        .filter((_, index) => includeAll || index === 0)
        .map(p => `${p.from.datePrint()} - ${p.to.datePrint()}`)
        .join("\n");
      return dates
        ? dates
        : route.procurementPeriods!.length
        ? t("RankingInProgress")
        : "";
    },
    pickup(route: AgreementRouteDto): string {
      return `${route.pickupCounty}, ${route.pickupZipcode}, ${isoName(
        route.pickupCountry
      )}`;
    },
    delivery(route: AgreementRouteDto): string {
      return `${route.deliveryCounty}, ${route.deliveryZipcode}, ${isoName(
        route.deliveryCountry
      )}`;
    },
    valueForChangeDetection(route: AgreementRouteDto): any[] {
      return [
        route.tag,
        route.hidden,
        route.estimatedNoOfLoads,
        route.specialRequirements,
        route.customsInfoRequired,
        ...route.truckTypes!
      ];
    },
    edited(original: any[], route: AgreementRouteDto): boolean {
      const newVals = this.valueForChangeDetection(route);
      if (original.length !== newVals.length) {
        return true;
      }
      for (let i = 0; i < original.length; i++) {
        if (original[i] !== newVals[i]) {
          return true;
        }
      }
      return false;
    },
    itemsEdited(routeIds: number[]) {
      const routes = this.routes.filter(r => routeIds.includes(r.id));
      this.tracker.editingRoutes
        .map(e => ({ e: e, r: routes.find(r => r.id === e.id) }))
        .filter(x => x.r !== undefined)
        .map(x => (x.e.edited = this.edited(x.e.originalValue, x.r!)));
    },
    tagEdited(routeIds: number[]) {
      this.tracker.editingTab = RouteTab.Tag;
      this.itemsEdited(routeIds);
    },
    applyAllTag(tag: string) {
      const routeIds = this.sortedRoutes.map(r => r.id);
      this.sortedRoutes.map(r => (r.tag = tag));
      this.tagEdited(routeIds);
    },
    hiddenEdited(routeIds: number[]) {
      this.tracker.editingTab = RouteTab.Visibility;
      this.itemsEdited(routeIds);
    },
    toggleAllHidden() {
      const routeIds = this.sortedRoutes.map(r => r.id);
      const newState = this.allHidden === true ? false : true;
      this.sortedRoutes.map(r => (r.hidden = newState));
      this.hiddenEdited(routeIds);
    },
    estimateEdited(routeIds: number[]) {
      this.tracker.editingTab = RouteTab.Estimate;
      this.itemsEdited(routeIds);
    },
    applyAllEstimateHistory() {
      const routeIds = this.sortedRoutes.map(r => r.id);
      this.sortedRoutes.map(
        r =>
          (r.estimatedNoOfLoads = r.estimateFromHistoricalPeriod
            ? r.estimateFromHistoricalPeriod
            : (null as any))
      );
      this.estimateEdited(routeIds);
    },
    specialRequirementsEdited(routeIds: number[]) {
      this.tracker.editingTab = RouteTab.SpecReq;
      this.itemsEdited(routeIds);
    },
    applyAllSpecialRequirements(specReq: string) {
      const routeIds = this.sortedRoutes.map(r => r.id);
      this.sortedRoutes.map(r => (r.specialRequirements = specReq));
      this.specialRequirementsEdited(routeIds);
    },
    customsInfoRequiredEdited(routeIds: number[]) {
      this.tracker.editingTab = RouteTab.SpecReq;
      this.itemsEdited(routeIds);
    },
    toggleAllCustomsInfoRequired() {
      const routeIds = this.sortedRoutes.map(r => r.id);
      const newState = this.allCustomsInfoRequired === true ? false : true;
      this.sortedRoutes.map(r => (r.customsInfoRequired = newState));
      this.customsInfoRequiredEdited(routeIds);
    },
    truckTypesEdited(routeIds: number[]) {
      this.tracker.editingTab = RouteTab.TruckTypes;
      this.itemsEdited(routeIds);
    },
    allHasTruckTypeX(tt: TruckType): boolean | undefined {
      const routes = this.sortedRoutes.filter(r =>
        this.tracker.editingRoutes.some(e => e.id === r.id)
      );
      if (routes.every(r => r.truckTypes!.includes(tt))) {
        return true;
      }
      if (routes.some(r => r.truckTypes!.includes(tt))) {
        return undefined;
      }
      return false;
    },
    toggleTruckType(route: AgreementRouteDto, tt: TruckType) {
      if (route.truckTypes!.includes(tt)) {
        route.truckTypes = route.truckTypes!.filter(t => t !== tt);
      } else {
        route.truckTypes = [...route.truckTypes!, tt].sort();
      }
      this.truckTypesEdited([route.id]);
    },
    allToggleTruckType(tt: TruckType) {
      const remove = this.sortedRoutes.every(r => r.truckTypes!.includes(tt));
      this.sortedRoutes.map(r => {
        if (remove) {
          r.truckTypes = r.truckTypes!.filter(t => t !== tt);
        } else if (!r.truckTypes!.includes(tt)) {
          r.truckTypes = [...r.truckTypes!, tt].sort();
        }
      });
      this.truckTypesEdited(this.sortedRoutes.map(r => r.id));
    },
    fetchEstimateHistory(from: MomentX, to: MomentX) {
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .estimateFromHistoricalPeriod(
          new EstimateFromHistoricalPeriodSearchCriteria({
            cargoType: this.filter.cargoType,
            from: from,
            to: to
          })
        )
        .then(res => {
          res.map(x =>
            this.routes
              .filter(r => r.routeId === x.routeId)
              .map(r => (r.estimateFromHistoricalPeriod = x.estimate))
          );
          this.estimateHistoryDialog = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    deleteAgreementRoute(id: number) {
      popupDialog({
        title: t("Remove"),
        body: t("ConfirmRemoveAgreementRoute"),
        btnText1: t("Remove"),
        btnColor1: "error",
        btnCallback1: () => {
          this.actuallyDeleteAgreementRoute(id);
        },
        btnText2: t("Cancel"),
        btnCallback2: () => {}
      });
    },
    actuallyDeleteAgreementRoute(id: number) {
      this.deleteInProgress = id;
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .deleteAgreementRoute(id)
        .then(res => {
          if (res) {
            pushNotification(t("Deleted"), "mdi-trash-can-outline");
            this.$emit("search");
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    customSort(
      items: AgreementRouteDto[],
      sortByArr: string[],
      sortDescArr: boolean[]
    ) {
      const sortBy = sortByArr[0];
      const sortDesc = sortDescArr[0];
      const c = (a: AgreementRouteDto, b: AgreementRouteDto) =>
        stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      items = items.sort(
        (a, b) =>
          (sortDesc ? -1 : 1) * c(a, b) ||
          stringCompare(this.pickup(a), this.pickup(b)) ||
          stringCompare(this.delivery(a), this.delivery(b))
      );
      return items;
    }
  },
  mounted() {
    this.$root.$on("import-estimate", (estimates: EstimateDto[]) => {
      this.tableOptions.itemsPerPage = -1;
      this.$emit("clearSelection");
      this.$nextTick(() => {
        this.routes.map(x => {
          estimates
            .filter(y => y.agreementRouteId === x.id)
            .map(y => (x.estimatedNoOfLoads = y.estimate));
        });
        this.estimateEdited(this.routes.map(r => r.id));
      });
    });
    this.$root.$on("export-estimate", (callback: Function) => {
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .exportToExcel(
          this.filter.cargoType,
          this.sortedRoutes.map(r => r.id)
        )
        .then(res => {
          excelPrompt(res);
          callback();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    });
    this.$root.$on("editSpecReq", () => {
      this.specReqDialog = true;
    });
    this.$root.$on("editTag", () => {
      this.tagDialog = true;
    });
    this.$root.$on("openHistoryDialog", () => {
      this.estimateHistoryDialog = true;
    });
  },
  data: (): {
    estimateHistoryDialog: boolean;
    deleteInProgress: number;
    tagDialog: boolean;
    specReqDialog: boolean;
    baseHeaders: TableHeader[];
    extraHeaders: TableHeader[];
    tableOptions: TableOptions;
  } => ({
    estimateHistoryDialog: false,
    deleteInProgress: 0,
    tagDialog: false,
    specReqDialog: false,
    baseHeaders: [
      { value: "routeId", text: "RouteID", sortValue: true, width: 75 },
      { value: "pickup", text: "PickupLocation", sortValue: true },
      { value: "delivery", text: "DeliveryLocation", sortValue: true },
      { value: "currency", text: "Currency", sortValue: true, width: 80 },
      { value: "distance", text: "Distance", sortValue: true, width: 80 },
      { value: "tag", text: "Tag", sortValue: true, width: 110 },
      {
        value: "estimatedNoOfLoads",
        sortValue: true,
        text: "",
        untranslated: true,
        width: 1
      }
    ],
    extraHeaders: [
      {
        value: "procurementPeriods",
        text: "Procurement",
        id: RouteTab.List,
        sortValue: true,
        width: 200
      },
      {
        value: "agreementPeriods",
        text: "Agreement",
        id: RouteTab.List,
        sortValue: true,
        width: 200
      },
      {
        value: "specIcon",
        text: "",
        id: RouteTab.List,
        untranslated: true,
        width: 1
      },
      {
        value: "deleteIcon",
        text: "",
        id: RouteTab.List,
        untranslated: true,
        width: 1
      },
      {
        value: "hidden",
        text: "Hidden",
        id: RouteTab.Visibility,
        sortValue: true,
        width: 90
      },
      {
        value: "estimateHistory",
        text: "History",
        id: RouteTab.Estimate,
        width: 60
      },
      {
        value: "specialRequirements",
        text: "SpecialRequirements",
        sortValue: true,
        id: RouteTab.SpecReq,
        width: 320
      },
      {
        value: "customsInfoRequired",
        text: "Customs",
        sortValue: true,
        id: RouteTab.SpecReq,
        width: 80
      },
      {
        value: "truckTypes",
        text: "",
        customHeader: true,
        id: RouteTab.TruckTypes,
        width: 500
      }
    ],
    tableOptions: new TableOptions({
      itemsPerPage: 100,
      sortBy: ["pickup"],
      sortDesc: [false]
    })
  })
});
