






























































































import Vue from "vue";
import { LoadDetailsBaseValuesDto } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import AppendixConsigneeDialog from "@/components/web/loads/shared/appendix/AppendixConsigneeDialog.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import { truckTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    values: LoadDetailsBaseValuesDto
  },
  components: {
    ReadOnlyText,
    AppendixConsigneeDialog,
    GreyTitle
  },
  computed: {
    distance(): string {
      if (this.values.distance) {
        return this.values.distance.toString();
      }
      return "-";
    },
    emission(): string {
      if (this.values.emission) {
        return this.values.emission.toString();
      }
      return "-";
    }
  },
  methods: {
    truckTypeName: truckTypeName,
    showConsigneeInfo() {
      this.selectedConsigneeId = this.values.consigneeAppendixId;
    },
    closeConsigneeInfo() {
      this.selectedConsigneeId = undefined;
    }
  },
  data: (): {
    selectedConsigneeId?: Number | undefined;
  } => ({
    selectedConsigneeId: undefined
  })
});
