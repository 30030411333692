



























































import Vue from "vue";

export default Vue.extend({
  props: {
    value: [Number, String, Boolean, Object],
    label: String,
    placeholder: String,
    items: Array,
    itemValue: String,
    itemText: String,
    tabindex: String,
    innerClass: String,
    disabled: Boolean,
    readonly: Boolean,
    loading: Boolean,
    clearable: Boolean,
    rules: Array,
    customItem: Boolean,
    asterisk: Boolean
  },
  computed: {
    computedItemValue(): string {
      return this.itemValue === undefined ? "id" : this.itemValue;
    },
    computedItemText(): string {
      return this.itemText === undefined ? "text" : this.itemText;
    }
  },
  methods: {
    input(val: any) {
      this.$emit("input", val);
    }
  }
});
