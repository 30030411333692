




































































































import Vue from "vue";
import {
  MailviewerClient,
  EmailModel,
  ApiConfiguration,
  EmailSearchCriteria,
  UserConfigurationType,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import Spinner from "@/components/shared/ui/Spinner.vue";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    TextMulti,
    TextSingle,
    NumberInput,
    Spinner
  },
  computed: {
    searchCriteria(): EmailSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.Mailviewer,
        1
      );
    }
  },
  methods: {
    search(): void {
      this.loading = true;
      this.searchCriteria.toDate = this.searchCriteria.toDate.endOfDay();
      new MailviewerClient(new ApiConfiguration(this.$store))
        .emails(this.searchCriteria)
        .then(result => {
          this.loading = false;
          this.emails = result;
          this.index = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.Mailviewer,
          subTypeId: 1,
          actorType: 0
        })
        .then(() => {
          this.search();
        });
    },
    dates(email: EmailModel): string {
      return `RegDate: ${email.regDate.dateTimePrint()} | SendDate: ${
        email.sendDate ? email.sendDate.dateTimePrint() : "NULL"
      }`;
    },
    retries(email: EmailModel): string {
      return email.retryCount === 0 ? "" : `| Retries: ${email.retryCount}`;
    },
    emailText(index: number): string {
      if (index >= this.emails.length) {
        return "Select an email";
      }
      return this.emails[index].body!.replaceAll("<BR/>", "\n");
    }
  },
  mounted() {
    this.search();
  },
  data: (): {
    loading: boolean;
    emails: EmailModel[];
    index: number;
    retryCountItems: RepositoryDto[];
  } => ({
    loading: false,
    emails: [],
    index: 0,
    retryCountItems: [
      new RepositoryDto({
        id: true as any,
        text: "Med"
      }),
      new RepositoryDto({
        id: false as any,
        text: "Utan"
      }),
      new RepositoryDto({
        id: null as any,
        text: "Båda"
      })
    ]
  })
});
