

























































import Vue from "vue";
import { stringSearchHit } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  props: {
    value: [Number, String],
    label: String,
    placeholder: String,
    items: Array,
    itemValue: String,
    itemText: [String, Function],
    tabindex: String,
    autofocus: Boolean,
    innerClass: String,
    customItem: Boolean,
    rules: Array,
    asterisk: Boolean,
    disabled: Boolean,
    loading: Boolean
  },
  computed: {
    computedItemValue(): string | undefined {
      if (this.itemValue) {
        return this.itemValue;
      } else {
        return "id";
      }
    },
    computedItemText(): Function | string | undefined {
      if (this.itemText) {
        return this.itemText;
      } else {
        return "text";
      }
    }
  },
  methods: {
    input(val: any) {
      this.$emit("input", val);
    },
    searchInput(val: any) {
      if (val === "" || val === undefined) {
        this.input(null);
      }
    },
    filter(_: any, queryText: string, itemText: string) {
      return stringSearchHit(queryText, itemText, true);
    }
  }
});
