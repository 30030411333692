


























































































import Vue from "vue";
import {
  ApiConfiguration,
  BackgroundTaskClient,
  BackgroundTaskDto,
  BackgroundTaskStatusDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import { triggerStateName } from "@/scripts/misc/enumNames";
import { stringCompare } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  components: {
    SearchCard
  },
  methods: {
    triggerStateName: triggerStateName,
    fetchJobs() {
      this.loading = true;
      new BackgroundTaskClient(new ApiConfiguration(this.$store))
        .list()
        .then(res => {
          this.jobs = res.sort(
            (a, b) =>
              stringCompare(a.repeatInterval, b.repeatInterval) ||
              a.startAt!.unix() - b.startAt!.unix()
          );
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    updateJob(taskId: number, enabled: boolean) {
      this.loading = true;
      new BackgroundTaskClient(new ApiConfiguration(this.$store))
        .update(
          new BackgroundTaskDto({
            taskId: taskId,
            enabled: enabled
          })
        )
        .then(() => {
          this.loading = false;
          this.fetchJobs();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    runJob(taskId: number) {
      this.loading = true;
      new BackgroundTaskClient(new ApiConfiguration(this.$store))
        .run(taskId)
        .then(() => {
          this.loading = false;
          this.fetchJobs();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    startSafe() {
      const unsafe = [8, 9, 13, 16, 17];
      for (let taskId = 1; taskId <= this.jobs.length; taskId++) {
        if (!unsafe.includes(taskId)) {
          this.updateJob(taskId, true);
        }
      }
    },
    stopAll() {
      this.updateJob(0, false);
    }
  },
  mounted() {
    this.fetchJobs();
  },
  data: (): {
    loading: boolean;
    jobs: BackgroundTaskStatusDto[];
  } => ({
    loading: false,
    jobs: []
  })
});
