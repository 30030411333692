























import Vue from "vue";
import { UserConfigurationType } from "@/scripts/cld.api";
import { LoadListTab, spotTenderTabId } from "@/scripts/types";
import MobileLoadListTable from "@/components/mobile/MobileLoadListTable.vue";
import MobileLoadListTabs from "@/components/mobile/MobileLoadListTabs.vue";
import { gets } from "@/scripts/store/constants";
import { UserConfigurationEntry } from "@/scripts/store/userConfModule";

export default Vue.extend({
  components: {
    MobileLoadListTable,
    MobileLoadListTabs
  },
  computed: {
    tabs(): LoadListTab[] {
      const tabs: LoadListTab[] = this.$store.getters[gets.allUserConfs](
        UserConfigurationType.LoadTab
      ).map((c: UserConfigurationEntry) => c.conf);
      return tabs.filter(x => x.id !== spotTenderTabId);
    },
    selectedTab(): LoadListTab {
      let tab: LoadListTab | undefined = this.tabs.find(
        t => t.id === this.selectedTabId
      );
      if (tab === undefined) {
        tab = this.tabs[0];
      }
      return tab!;
    }
  },
  data: (): {
    selectedTabId: number;
  } => ({
    selectedTabId: 1
  })
});
