


















































































import Vue from "vue";
import { SurveySubmissionDto } from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";

export default Vue.extend({
  components: {
    SearchCard
  },
  props: {
    survey: Object,
    submissions: Array as () => SurveySubmissionDto[]
  },
  computed: {
    title(): string {
      return `${this.$t("Survey_SustainabilitySurvey")} ${
        this.survey.iteration
      }`;
    },
    surveyInProgress() {
      return (this.submissions as SurveySubmissionDto[]).some(
        s => s.completed === false
      );
    },
    noSubmission() {
      return this.submissions.length === 0;
    }
  },
  methods: {
    submissionTitle(submission: SurveySubmissionDto) {
      let res = submission.completed
        ? this.$t("Survey_Completed")
        : this.$t("Survey_Ongoing");
      res += ` ${submission.answerDate.datePrint()}`;
      return res;
    },
    submissionSubtitle(submission: SurveySubmissionDto) {
      let res = submission.loadUserName;
      if (submission.completed) {
        res = `${submission.respondentName}, ${submission.respondentRole} (${res})`;
      }
      return res;
    },
    color(submission: SurveySubmissionDto) {
      if (!submission.completed) {
        return "warning";
      } else if (
        (this.submissions as SurveySubmissionDto[]).some(
          s => s.completed && s.answerDate.unix() > submission.answerDate.unix()
        )
      ) {
        return "grey";
      } else {
        return "icon-green";
      }
    },
    icon(submission: SurveySubmissionDto) {
      return submission.completed
        ? "mdi-check-circle-outline"
        : "mdi-progress-clock";
    },
    submissionClicked(surveySubmissionId?: number, readonly?: boolean) {
      if (readonly === false) {
        readonly = undefined;
      }
      this.$emit(
        "submissionClicked",
        this.survey.id,
        surveySubmissionId,
        readonly
      );
    }
  }
});
