
































import Vue from "vue";

export interface MenuButtonOption {
  id: number;
  text: string | undefined;
  icon: string;
}

export default Vue.extend({
  props: {
    text: String,
    options: Array as () => MenuButtonOption[],
    disabled: Boolean
  },
  computed: {
    singleOption(): boolean {
      return this.options.length === 1;
    }
  },
  methods: {
    menuClick() {
      if (!this.singleOption) {
        return;
      }
      this.optionClick(this.options[0].id);
    },
    optionClick(id: number) {
      this.$emit("input", id);
    }
  }
});
