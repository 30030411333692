import { Module } from "vuex";
import {
  ActorType,
  ApiConfiguration,
  CargoType,
  ClientAgreementSearchCriteria,
  LoadStatus,
  SupplierAgreementSearchCriteria,
  UserConfigurationDto,
  UserConfigurationType,
  UsersClient
} from "@/scripts/cld.api";
import { RootState } from "@/scripts/store/store";
import { mutations, actions, gets } from "@/scripts/store/constants";
import createUserConf from "@/scripts/store/createUserConf";
import neverHappens from "@/scripts/misc/neverHappens";
import { AgreementRouteFilter, spotTenderTabId } from "../types";

export interface UserConfigurationEntry {
  type: UserConfigurationType;
  subTypeId: number;
  conf: Object;
}

export interface UserConfigurationState {
  confs: UserConfigurationEntry[];
}

const state: UserConfigurationState = {
  confs: []
};

export const userConfModule: Module<UserConfigurationState, RootState> = {
  state: state,
  getters: {
    [gets.userConf]: state => {
      return (type: UserConfigurationType, subTypeId: number): Object => {
        return state.confs.find(
          c => c.type === type && c.subTypeId === subTypeId
        )!.conf;
      };
    },
    [gets.allUserConfs]: state => {
      return (type: UserConfigurationType): UserConfigurationEntry[] => {
        return [...state.confs.filter(c => c.type === type)].sort(
          (a, b) => a.subTypeId - b.subTypeId
        );
      };
    }
  },
  mutations: {
    [mutations.setUserConf]: (state, conf: UserConfigurationEntry) => {
      state.confs = [
        conf,
        ...state.confs.filter(
          c => c.type !== conf.type || c.subTypeId !== conf.subTypeId
        )
      ];
    },
    [mutations.emptyUserConf]: state => {
      state.confs = [];
    }
  },
  actions: {
    [actions.resetUserConf]: (
      context,
      data: {
        type: UserConfigurationType;
        subTypeId: number;
        actorType: ActorType;
      }
    ) => {
      const entry = createUserConf(data.type, data.subTypeId, data.actorType);
      switch (entry.type) {
        case UserConfigurationType.ClientAgreement:
        case UserConfigurationType.ClientProcurement:
          (entry.conf as ClientAgreementSearchCriteria).unitBasePriceDate = context.getters[
            gets.userConf
          ](data.type, data.subTypeId).unitBasePriceDate;
          break;
        case UserConfigurationType.SupplierAgreement:
        case UserConfigurationType.SupplierProcurement:
          (entry.conf as SupplierAgreementSearchCriteria).unitBasePriceDate = context.getters[
            gets.userConf
          ](data.type, data.subTypeId).unitBasePriceDate;
          break;
        case UserConfigurationType.AgreementRoute:
          (entry.conf as AgreementRouteFilter).cargoType = context.getters[
            gets.userConf
          ](data.type, data.subTypeId).cargoType;
          break;
        case UserConfigurationType.LoadList:
        case UserConfigurationType.LoadTab:
        case UserConfigurationType.CreateLoad:
        case UserConfigurationType.ProcurementDraft:
        case UserConfigurationType.CostReport:
        case UserConfigurationType.InvoiceReport:
        case UserConfigurationType.LoadStatisticsReport:
        case UserConfigurationType.AgreementStatisticsReport:
        case UserConfigurationType.DeviationReport:
        case UserConfigurationType.DistanceReport:
        case UserConfigurationType.MobilePopup:
        case UserConfigurationType.SupplierProcurementConfig:
        case UserConfigurationType.CalendarConfiguration:
        case UserConfigurationType.ReadDocuments:
        case UserConfigurationType.ProcurementAnalytics:
        case UserConfigurationType.QualityPoints:
        case UserConfigurationType.ClientMonitoring:
        case UserConfigurationType.InvoicedFees:
        case UserConfigurationType.Mailviewer:
        case UserConfigurationType.Explorer:
        case UserConfigurationType.Distance:
        case UserConfigurationType.InvoiceGenerator:
        case UserConfigurationType.HeatMap:
          break;
        default:
          neverHappens(entry.type);
      }

      context.commit(mutations.setUserConf, entry);
    },
    [actions.initUserConf]: (
      context,
      data: { actorType: ActorType; confs: UserConfigurationDto[] }
    ) => {
      context.commit(mutations.emptyUserConf);
      const confs: UserConfigurationEntry[] = [];
      Object.values(UserConfigurationType)
        .filter(t => !isNaN(Number(t)))
        .map(t => t as UserConfigurationType)
        .map(t => {
          switch (t) {
            case UserConfigurationType.ClientAgreement:
            case UserConfigurationType.ClientProcurement:
            case UserConfigurationType.SupplierAgreement:
            case UserConfigurationType.SupplierProcurement:
              confs.push(createUserConf(t, CargoType.Road, data.actorType));
              confs.push(createUserConf(t, CargoType.Railroad, data.actorType));
              confs.push(
                createUserConf(t, CargoType.Container, data.actorType)
              );
              confs.push(
                createUserConf(t, CargoType.MachineTransport, data.actorType)
              );
              break;
            case UserConfigurationType.LoadList:
            case UserConfigurationType.LoadTab:
              confs.push(createUserConf(t, LoadStatus.Ordered, data.actorType));
              confs.push(createUserConf(t, LoadStatus.Booked, data.actorType));
              confs.push(createUserConf(t, LoadStatus.Loaded, data.actorType));
              confs.push(
                createUserConf(t, LoadStatus.Delivered, data.actorType)
              );
              if (
                [ActorType.Client, ActorType.Supplier].includes(data.actorType)
              ) {
                confs.push(createUserConf(t, spotTenderTabId, data.actorType));
              }
              break;
            case UserConfigurationType.CreateLoad:
            case UserConfigurationType.ProcurementDraft:
            case UserConfigurationType.AgreementRoute:
            case UserConfigurationType.CostReport:
            case UserConfigurationType.InvoiceReport:
            case UserConfigurationType.LoadStatisticsReport:
            case UserConfigurationType.AgreementStatisticsReport:
            case UserConfigurationType.DeviationReport:
            case UserConfigurationType.DistanceReport:
            case UserConfigurationType.MobilePopup:
            case UserConfigurationType.SupplierProcurementConfig:
            case UserConfigurationType.CalendarConfiguration:
            case UserConfigurationType.ReadDocuments:
            case UserConfigurationType.ProcurementAnalytics:
            case UserConfigurationType.QualityPoints:
            case UserConfigurationType.ClientMonitoring:
            case UserConfigurationType.InvoicedFees:
            case UserConfigurationType.Mailviewer:
            case UserConfigurationType.Explorer:
            case UserConfigurationType.Distance:
            case UserConfigurationType.InvoiceGenerator:
            case UserConfigurationType.HeatMap:
              confs.push(createUserConf(t, 1, data.actorType));
              break;
            default:
              neverHappens(t);
          }
        });
      for (const conf of confs) {
        context.commit(mutations.setUserConf, conf);
      }
      return new Promise<void>(resolve => {
        for (const conf of data.confs) {
          context.commit(
            mutations.setUserConf,
            createUserConf(
              conf.type,
              conf.subTypeId,
              data.actorType,
              conf.configuration
            )
          );
        }
        resolve();
      });
    },
    [actions.saveUserConf]: (
      context,
      data: { type: UserConfigurationType; subTypeId: number }
    ) => {
      const configuration = context.getters[gets.userConf](
        data.type,
        data.subTypeId
      );
      return new UsersClient(new ApiConfiguration(context))
        .userConfiguration(
          new UserConfigurationDto({
            type: data.type,
            subTypeId: data.subTypeId,
            configuration: JSON.stringify(configuration)
          })
        )
        .catch(error => {
          context.dispatch(actions.handleApiError, error);
        });
    }
  }
};
