var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"value":_vm.selectedAgreements,"headers":_vm.headers,"items":_vm.agreements,"expanded":_vm.expandedAgreements,"loading":_vm.loading,"loading-text":_vm.$t(_vm.loadingText) + '...',"no-data-text":_vm.$t('NothingToShow'),"custom-sort":_vm.customSort,"height":_vm.tableHeight,"mobile-breakpoint":0,"options":_vm.options,"item-key":"id","hide-default-header":"","fixed-header":"","dense":"","footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [100],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"selectable-key":"selectable"},on:{"update:options":function($event){_vm.options=$event},"item-selected":_vm.itemSelected,"toggle-select-all":_vm.allItemsSelected,"pagination":_vm.unselectAllItems},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return [_c('div',{staticClass:"medium-padding-top tiny-margin-top large-padding-left tiniest-margin-left"},[_c('CheckboxTiny',{attrs:{"value":props.everyItem ? true : props.someItems ? undefined : false},on:{"input":on['toggle-select-all']}})],1)]},proxy:true},{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationClient',
              pickup: true,
              cargoType: _vm.cargoTypeId
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.searchCriteria.pickupLocationCounty,
              zipCode: _vm.searchCriteria.pickupLocationZipCode,
              countryId: _vm.searchCriteria.pickupCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.pickupLocationCounty = val.county;
                _vm.searchCriteria.pickupLocationZipCode = val.zipCode;
                _vm.searchCriteria.pickupCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationClient',
              pickup: false,
              cargoType: _vm.cargoTypeId
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.searchCriteria.deliveryLocationCounty,
              zipCode: _vm.searchCriteria.deliveryLocationZipCode,
              countryId: _vm.searchCriteria.deliveryCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.deliveryLocationCounty = val.county;
                _vm.searchCriteria.deliveryLocationZipCode = val.zipCode;
                _vm.searchCriteria.deliveryCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.currencyName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.currencyType),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "currencyType", $$v)},expression:"searchCriteria.currencyType"}})]},proxy:true},{key:"filter.tag",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'tag', cargoType: _vm.cargoTypeId },"placeholder":_vm.$t('Tag')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.tag),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "tag", $$v)},expression:"searchCriteria.tag"}})]},proxy:true},{key:"filter.baseIndexName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'baseIndexDate' },"placeholder":_vm.$t('BaseIndex')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.baseIndexDate),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "baseIndexDate", $$v)},expression:"searchCriteria.baseIndexDate"}})]},proxy:true}],null,true)})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_c('v-btn',{attrs:{"icon":"","width":"25","height":"25"},on:{"click":function($event){return _vm.expand(item)}}},[_c('v-icon',{class:{ flipped: _vm.expandedAgreements.indexOf(item) !== -1 }},[_vm._v(" mdi-chevron-down ")])],1),_c('CheckboxTiny',{attrs:{"innerClass":"transparent-if-disabled","disabled":!item.selectable,"value":_vm.selectedAgreements.indexOf(item) !== -1},on:{"input":function($event){return _vm.select(item)}}}),_c('v-btn',{staticClass:"transparent-if-disabled",attrs:{"disabled":!item.selectable,"icon":"","width":"25","height":"25"},on:{"click":function($event){return _vm.openRankingDialog(item.id)}}},[_c('v-icon',{attrs:{"size":"21"}},[_vm._v(" mdi-pencil ")])],1),(
            item.specialRequirements !== null &&
              item.specialRequirements !== ''
          )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red","size":"22"}},on),[_vm._v(" mdi-exclamation-thick ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.specialRequirements))])]):_vm._e()],1)]}},{key:"item.pickupLocation",fn:function(ref){
          var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.pickup(item),"width":"185","clickable":""},on:{"click":function($event){return _vm.expand(item)}}})]}},{key:"item.deliveryLocation",fn:function(ref){
          var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.delivery(item),"width":"255","clickable":""},on:{"click":function($event){return _vm.expand(item)}}})]}},{key:"item.agreementPeriod",fn:function(ref){
          var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":((item.fromDate.datePrint()) + " - " + (item.toDate.datePrint())),"width":"185","clickable":""},on:{"click":function($event){return _vm.expand(item)}}})]}},{key:"item.procurementPeriod",fn:function(ref){
          var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":((item.procurementFromDate.datePrint()) + " - " + (item.procurementToDate.datePrint())),"width":"185","clickable":""},on:{"click":function($event){return _vm.expand(item)}}})]}},{key:"item.baseIndexName",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines clickable",on:{"click":function($event){return _vm.goToIndexes(item)}}},on),[_vm._v(" "+_vm._s(item.baseIndexName)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.indexTooltip(item))+" ")])]}},{key:"item.estimatedNoOfLoads",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.expand(item)}}},[_vm._v(" "+_vm._s(item.estimatedNoOfLoads)+" ")])]}},{key:"item.currencyName",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.expand(item)}}},[_vm._v(" "+_vm._s(item.currencyName)+" ")])]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"inner-table",attrs:{"colspan":9}},[(item.truckTypes)?_c('div',[(item.truckTypes.length === 0)?_c('v-progress-linear',{attrs:{"height":"3","indeterminate":""}}):_vm._e(),_vm._l((item.truckTypes),function(truckType,index){return _c('ProcurementTableExpansion',{key:index,attrs:{"cargoTypeId":_vm.cargoTypeId,"truckType":truckType}})})],2):_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_vm._v(" "+_vm._s(_vm.$t("NothingToShow"))+" "),_c('v-spacer')],1)],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }