

































































































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import { localeCode } from "@/scripts/language/i18n";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import TimePickerDialog from "@/components/shared/input/TimePickerDialog.vue";

export default Vue.extend({
  components: {
    InfoTooltip,
    TimePickerDialog
  },
  props: {
    value: MomentX,
    label: String,
    compact: Boolean,
    allowNull: Boolean,
    invalidText: String,
    gteToday: Boolean,
    gteNow: Boolean,
    gteDates: Array as () => (MomentX | undefined)[],
    gteText: String,
    lteDate: MomentX,
    lteText: String,
    tabindex: String,
    infoTooltip: String,
    withTime: Boolean,
    asterisk: Boolean,
    rules: Array as () => Function[],
    disabled: Boolean,
    timeEnabledAnyway: Boolean
  },
  watch: {
    menu() {
      if (!this.menu) {
        this.$emit("done");
      }
    }
  },
  computed: {
    formattedDate: {
      get(): string | null {
        return this.toDateString(this.value);
      },
      set(dstring: string) {
        const date = dstring ? this.toMoment(dstring) : null;
        this.$emit("input", date);
      }
    },
    locale(): string {
      return localeCode();
    }
  },
  methods: {
    toMoment(dstring: string | null): MomentX {
      return new MomentX(dstring, "YYYY-MM-DD HH:mm");
    },
    toDateString(date: MomentX): string | null {
      if (date) {
        if (this.withTime) {
          return date.shortDateTimePrintSeparated();
        }
        return date.datePrint();
      }
      return null;
    },
    eventDate(dstring: string): boolean {
      const timePoints = [...Array(this.withTime ? 24 : 1).keys()].map(h =>
        new MomentX(dstring)
          .startOfDay()
          .addHours(h)
          .dateTimePrint()
      );
      return !timePoints
        .map(t => this.computedRules(t).every(r => r === true))
        .reduce((a, b) => a || b, false);
    },
    computedRules(dstring: string | null): (boolean | string)[] {
      if (!dstring && this.allowNull) {
        return [];
      }
      const date = this.toMoment(dstring);
      const rules = this.rules as
        | { (date: MomentX): boolean | string }[]
        | undefined;
      const validations = rules ? rules.map(rule => rule(date)) : [true];
      return [
        ...validations,
        this.validMoment(date),
        this.greaterThanEqualToday(date),
        this.greaterThanEqualNow(date),
        this.greaterThanEqual(date),
        this.lessThanEqual(date)
      ];
    },
    validMoment(date: MomentX): boolean | string {
      return (
        date.isValid() ||
        (this.invalidText && !this.allowNull ? this.invalidText : false)
      );
    },
    greaterThanEqualToday(date: MomentX): boolean | string {
      if (this.gteToday !== true) {
        return true;
      }
      return (
        date.isSameOrAfter(new MomentX().startOfDay()) ||
        this.$t("DateBeforeToday").toString()
      );
    },
    greaterThanEqualNow(date: MomentX): boolean | string {
      if (this.gteNow !== true) {
        return true;
      }
      return (
        date.isSameOrAfter(new MomentX().addMinutes(-45)) ||
        this.$t("DateBeforeToday").toString()
      );
    },
    greaterThanEqual(date: MomentX): boolean | string {
      return (
        (this.gteDates || [])
          .filter(d => d !== undefined)
          .every(d => date.isSameOrAfter(d!)) ||
        this.gteText ||
        false
      );
    },
    lessThanEqual(date: MomentX): boolean | string {
      if (this.lteDate === undefined) {
        return true;
      }
      return date.isSameOrBefore(this.lteDate) || this.lteText || false;
    },
    setDate(dstring: string | null) {
      if (dstring) {
        const hours = this.value ? this.value.hours() : 0;
        const minutes = this.value ? this.value.minutes() : 0;
        this.formattedDate = this.toMoment(dstring)
          .withHours(hours)
          .withMinutes(minutes)
          .shortDateTimePrint();
      }
    },
    openDate() {
      this.datePicker = true;
      this.menu = true;
    },
    openTime() {
      this.datePicker = false;
      this.menu = true;
    },
    closeDate() {
      if (this.withTime) {
        this.datePicker = false;
      } else {
        this.menu = false;
      }
    },
    closeTime() {
      this.menu = false;
      //Timeout prevents flickering.
      setTimeout(() => {
        this.datePicker = true;
      }, 100);
    }
  },
  data: (): {
    menu: boolean;
    datePicker: boolean;
  } => ({
    menu: false,
    datePicker: true
  })
});
