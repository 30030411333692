







































import Vue from "vue";
import {
  ApiConfiguration,
  LoadListContainerDto,
  LoadOrderBy,
  LoadSearchCriteriaDto,
  LoadSearchCriteriaContainerDto,
  UserConfigurationType,
  ActorType,
  LoadsClient
} from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import { actions, gets } from "@/scripts/store/constants";
import { LoadListTab, TableOptions } from "@/scripts/types";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import LoadListTabs from "@/components/web/loads/shared/list/LoadListTabs.vue";
import LoadListTable from "@/components/web/loads/shared/list/LoadListTable.vue";
import LoadListTabsEdit from "@/components/web/loads/shared/list/LoadListTabsEdit.vue";
import LoadDialogs from "@/components/web/loads/shared/LoadDialogs.vue";
import nextUnique from "@/scripts/misc/nextUnique";
import { UserConfigurationEntry } from "@/scripts/store/userConfModule";

export default Vue.extend({
  components: {
    LoadListTabs,
    LoadListTable,
    LoadListTabsEdit,
    LoadDialogs
  },
  watch: {
    selectedTab() {
      this.search(true, false);
    },
    activityDate(newVal: MomentX, oldVal: MomentX) {
      let secondsInactive = newVal.unix() - oldVal.unix();
      if (secondsInactive > 45) {
        this.search(false, false);
      }
    }
  },
  computed: {
    activityDate(): MomentX {
      return this.$store.getters[gets.activityDate];
    },
    searchCriteria(): LoadSearchCriteriaDto {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.LoadList,
        this.selectedTab.id
      );
    },
    tabs(): LoadListTab[] {
      return this.$store.getters[gets.allUserConfs](
        UserConfigurationType.LoadTab
      ).map((c: UserConfigurationEntry) => c.conf);
    },
    sorting(): { orderBy: LoadOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as LoadOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    selectedTab(): LoadListTab {
      return this.tabs.find(t => t.id === this.selectedTabId)!;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  methods: {
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(false, false);
    },
    check(loadId: number) {
      if (this.checked.includes(loadId)) {
        this.checked = this.checked.filter(id => id !== loadId);
      } else {
        this.checked.push(loadId);
      }
    },
    search(goToFirstPage: boolean, saveUserConf: boolean) {
      this.checked = [];
      if (goToFirstPage) {
        this.tableOptions.page = 1;
        scrollTableToTop(this);
      }
      const unique = nextUnique();
      this.loading = unique;
      new LoadsClient(new ApiConfiguration(this.$store))
        .list(
          new LoadSearchCriteriaContainerDto({
            loadSearchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (saveUserConf) {
            this.$store.dispatch(actions.saveUserConf, {
              type: UserConfigurationType.LoadList,
              subTypeId: this.selectedTab.id
            });
          }
          this.loadListContainer = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.LoadList,
          subTypeId: this.selectedTab.id,
          actorType: this.actorType
        })
        .then(() => {
          this.tableOptions.sortBy = [LoadOrderBy.Id];
          this.tableOptions.sortDesc = [false];
          this.search(true, true);
        });
    }
  },
  data: (): {
    loading: number;
    tabEditDialog: boolean;
    selectedTabId: number;
    loadListContainer: LoadListContainerDto;
    tableOptions: TableOptions;
    checked: number[];
  } => ({
    loading: 0,
    tabEditDialog: false,
    selectedTabId: 1,
    loadListContainer: new LoadListContainerDto({
      count: 0,
      loads: []
    }),
    tableOptions: new TableOptions({
      itemsPerPage: 50,
      sortBy: [LoadOrderBy.Id],
      sortDesc: [false]
    }),
    checked: []
  })
});
