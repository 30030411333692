









































import Vue from "vue";
import { TableHeader } from "@/scripts/types";
import { LoadDetailsHistoryDto } from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import { stringCompare } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    historyRows: Array as () => LoadDetailsHistoryDto[] | undefined,
    dialog: Boolean
  },
  computed: {
    historyHeaders(): TableHeader[] {
      return [
        {
          value: "timeStamp",
          text: this.$t("Date").toString(),
          sortValue: true,
          width: 170
        },
        {
          value: "action",
          text: this.$t("Action").toString()
        },
        {
          value: "user",
          text: this.$t("Actor").toString(),
          sortValue: true,
          width: 150
        },
        {
          value: "description",
          text: this.$t("Description").toString()
        },
        {
          value: "why",
          text: this.$t("Why").toString()
        }
      ];
    }
  },
  methods: {
    customSort(
      items: LoadDetailsHistoryDto[],
      sortBy: string[],
      sortDesc: boolean[]
    ) {
      items.sort((a: LoadDetailsHistoryDto, b: LoadDetailsHistoryDto) => {
        if (sortBy[0] === "date") {
          return a.timeStamp.unix() - b.timeStamp.unix();
        } else if (sortBy[0] === "name") {
          return stringCompare(a.user!, b.user!);
        } else {
          return 0;
        }
      });

      if (sortDesc[0] === true) {
        items = items.reverse();
      }
      return items;
    },
    close() {
      this.$emit("close");
    }
  }
});
