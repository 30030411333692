


































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import {
  ApiConfiguration,
  SaveDiskFileToDocumentDto,
  DocumentsClient,
  FileParameter,
  FilesClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";

export type DocumentIdDiskNameDisplayName = {
  id: number;
  diskName: string;
  displayName: string;
};

export default Vue.extend({
  components: {
    DialogCard,
    TextSingle
  },
  props: {
    uploadedFile: Object as () => FileParameter | undefined,
    documentType: String as () => "load" | "message"
  },
  watch: {
    uploadedFile: {
      handler() {
        if (this.uploadedFile && this.uploadedFile.fileName) {
          if (this.uploadedFile.fileName.includes(".")) {
            const index = this.uploadedFile.fileName.indexOf(".");
            this.uploadedFile.fileName = this.uploadedFile.fileName.substring(
              0,
              index
            );
          }
          this.uploadedFile.fileName = this.uploadedFile.fileName.substring(
            0,
            30
          );
        }
      },
      immediate: true
    }
  },
  computed: {
    tooLongText(): string {
      return t("MaxXCharacters").replace("{0}", "30");
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    upload() {
      this.saveInProgress = true;
      new FilesClient(new ApiConfiguration(this.$store))
        .upload(this.uploadedFile)
        .then(diskName => {
          this.addToDb(diskName, this.uploadedFile!.fileName);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    addToDb(diskName: string, displayName: string) {
      const client = new DocumentsClient(new ApiConfiguration(this.$store));
      const apiCall =
        this.documentType === "load"
          ? client.saveToLoadGroup(
              new SaveDiskFileToDocumentDto({
                diskName: diskName,
                displayName: displayName
              })
            )
          : client.saveToMessageGroup(
              new SaveDiskFileToDocumentDto({
                diskName: diskName,
                displayName: displayName
              })
            );

      apiCall
        .then(id => {
          const document: DocumentIdDiskNameDisplayName = {
            id: id,
            displayName: displayName,
            diskName: diskName
          };
          this.$emit("document", document);
          this.saveInProgress = false;
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): { valid: boolean; saveInProgress: boolean } => ({
    valid: false,
    saveInProgress: false
  })
});
