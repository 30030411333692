


























































import Vue from "vue";
import {
  ApiConfiguration,
  LoadClientEditAssortedValuesDto,
  LoadClientEditContainersDto,
  OrderType,
  RepositoryClient,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    step: Number,
    assortedValues: LoadClientEditAssortedValuesDto,
    containerValues: LoadClientEditContainersDto,
    orderType: Number as () => OrderType
  },
  components: {
    Autocomplete,
    TextSingle,
    NumberInput,
    GreyTitle
  },
  computed: {
    visible(): boolean {
      return (
        this.orderType === OrderType.ClassicContainer &&
        this.containerValues !== undefined
      );
    }
  },
  methods: {
    fetchTerminals() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .terminals()
        .then(res => {
          this.terminals = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchTerminals();
  },
  data: (): {
    terminals: RepositoryDto[];
  } => ({
    terminals: []
  })
});
