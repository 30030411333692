import {
  UserLevel,
  ActorType,
  CargoType,
  Culture,
  Currency,
  DeviationCause,
  DocType,
  FeeType,
  FuelIndexType,
  InvoiceReportTreatedStatus,
  LoadFilterDaysBack,
  LoadFilterDaysForward,
  LoadStatus,
  MessageType,
  OtherPriceCause,
  RankingMethod,
  RepositoryDto,
  RoutingExactness,
  TruckType,
  LoadFilterDateType,
  PublishRuleType,
  DayType
} from "@/scripts/cld.api";
import store from "@/scripts/store/store";
import {
  actorTypeName,
  cargoTypeName,
  cultureName,
  currencyName,
  daysBackName,
  daysForwardName,
  deviationCauseName,
  docTypeName,
  feeTypeName,
  fuelIndexTypeName,
  loadStatusName,
  messageTypeName,
  otherPriceCauseName,
  rankingMethodName,
  userLevelName,
  routingExactnessName,
  treatedStatusName,
  truckTypeName,
  filterDateTypeName,
  publishRuleTypeName,
  dayTypeName
} from "@/scripts/misc/enumNames";
import neverHappens from "@/scripts/misc/neverHappens";
import { t } from "../language/i18n";
import { gets } from "../store/constants";

const enumList = (e: any, f: any): RepositoryDto[] => {
  return Object.values(e)
    .filter(v => !isNaN(Number(v)))
    .map(
      v =>
        new RepositoryDto({
          id: Number(v),
          text: f(Number(v))
        })
    );
};

export const actorTypes = (): RepositoryDto[] => {
  return enumList(ActorType, actorTypeName);
};

export const cargoTypes = (): RepositoryDto[] => {
  return enumList(CargoType, cargoTypeName);
};

export const actorCargoTypes = (): RepositoryDto[] => {
  return (store.getters[gets.userInfo].actorCargoTypes as CargoType[])
    .map(c => new RepositoryDto({ id: c, text: cargoTypeName(c) }))
    .sort((a, b) => a.id - b.id);
};

export const truckTypes = (): RepositoryDto[] => {
  return Object.values(TruckType)
    .filter(v => !isNaN(Number(v)))
    .map(v => v as TruckType)
    .sort((a, b) => cargoTypeByTruckType(a) - cargoTypeByTruckType(b) || a - b)
    .map(v => new RepositoryDto({ id: Number(v), text: truckTypeName(v) }));
};

export const cargoTypeByTruckType = (tt: TruckType): CargoType => {
  switch (tt) {
    case TruckType.TruckAndWagon:
      return CargoType.Road;
    case TruckType.Truck:
      return CargoType.Road;
    case TruckType.Wagon:
      return CargoType.Road;
    case TruckType.Trailer25:
      return CargoType.Road;
    case TruckType.Trailer30:
      return CargoType.Road;

    case TruckType.TwoShaft:
      return CargoType.Railroad;
    case TruckType.FourShaft:
      return CargoType.Railroad;
    case TruckType.FourShaftOpen:
      return CargoType.Railroad;

    case TruckType.Container20:
      return CargoType.Container;
    case TruckType.Container40:
      return CargoType.Container;
    case TruckType.Container40HC:
      return CargoType.Container;
    case TruckType.Container45HC:
      return CargoType.Container;

    case TruckType.TruckType10:
      return CargoType.MachineTransport;
    case TruckType.TruckType20:
      return CargoType.MachineTransport;
    case TruckType.TruckType30:
      return CargoType.MachineTransport;
    case TruckType.TruckType40:
      return CargoType.MachineTransport;
    case TruckType.TruckType50:
      return CargoType.MachineTransport;
    case TruckType.TruckType60:
      return CargoType.MachineTransport;
    case TruckType.TruckType70:
      return CargoType.MachineTransport;
    default:
      return neverHappens(tt);
  }
};

export const truckTypesByCargoType = (
  cargoType: CargoType
): RepositoryDto[] => {
  return truckTypes().filter(tt => cargoTypeByTruckType(tt.id) === cargoType);
};

export const actorTruckTypes = (): RepositoryDto[] => {
  return truckTypes().filter(tt =>
    actorCargoTypes()
      .map(c => c.id)
      .includes(cargoTypeByTruckType(tt.id))
  );
};

export const currencies = (): RepositoryDto[] => {
  return enumList(Currency, currencyName);
};

export const cultures = (): RepositoryDto[] => {
  return enumList(Culture, cultureName);
};

export const feeTypes = (): RepositoryDto[] => {
  return enumList(FeeType, feeTypeName);
};

export const rankingMethods = (): RepositoryDto[] => {
  return enumList(RankingMethod, rankingMethodName);
};

export const userLevels = (): RepositoryDto[] => {
  return enumList(UserLevel, userLevelName);
};

export const messageTypes = (): RepositoryDto[] => {
  return enumList(MessageType, messageTypeName);
};

export const dayTypes = (): RepositoryDto[] => {
  return enumList(DayType, dayTypeName);
};

export const treatedStatuses = (): RepositoryDto[] => {
  return enumList(InvoiceReportTreatedStatus, treatedStatusName);
};

export const otherPriceCauses = (): RepositoryDto[] => {
  return enumList(OtherPriceCause, otherPriceCauseName);
};

export const deviationCauses = (): RepositoryDto[] => {
  return enumList(DeviationCause, deviationCauseName);
};

export const daysBack = (): RepositoryDto[] => {
  return enumList(LoadFilterDaysBack, daysBackName);
};

export const daysForward = (): RepositoryDto[] => {
  return enumList(LoadFilterDaysForward, daysForwardName);
};

export const filterDateTypes = (): RepositoryDto[] => {
  return enumList(LoadFilterDateType, filterDateTypeName);
};

export const routingExactnesses = (): RepositoryDto[] => {
  return enumList(RoutingExactness, routingExactnessName);
};

export const docTypes = (): RepositoryDto[] => {
  return enumList(DocType, docTypeName);
};

export const loadStatuses = (): RepositoryDto[] => {
  return enumList(LoadStatus, loadStatusName);
};

export const yesNoItems = (): RepositoryDto[] => {
  return [
    new RepositoryDto({ id: true as any, text: t("Survey_Yes") }),
    new RepositoryDto({ id: false as any, text: t("Survey_No") })
  ];
};

export const activeInactiveItems = (): RepositoryDto[] => {
  return [
    new RepositoryDto({ id: true as any, text: t("Activated") }),
    new RepositoryDto({ id: false as any, text: t("Inactivated") })
  ];
};

export const fuelIndexTypes = (): RepositoryDto[] => {
  return enumList(FuelIndexType, fuelIndexTypeName);
};

export const publishRuleTypes = (): RepositoryDto[] => {
  return enumList(PublishRuleType, publishRuleTypeName);
};
