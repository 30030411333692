































































import Vue from "vue";
import {
  UserConfigurationType,
  ProcurementAnalyticsSearchCriteria,
  ProcurementAnalyticsContainerDto,
  RepositoryDto,
  RepositoryClient,
  ApiConfiguration
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import { currencyName } from "@/scripts/misc/enumNames";
import money from "@/scripts/misc/money";
import {
  actorCargoTypes,
  truckTypesByCargoType
} from "@/scripts/misc/enumLists";
import { actions, gets } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    Autocomplete
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    reportContainer: ProcurementAnalyticsContainerDto
  },
  watch: {
    "searchCriteria.cargoType": {
      handler() {
        this.searchCriteria.truckType = undefined;
        this.searchCriteria.supplierId = undefined;
      },
      immediate: true
    }
  },
  computed: {
    searchCriteria(): ProcurementAnalyticsSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ProcurementAnalytics,
        1
      );
    },
    truckTypes(): RepositoryDto[] {
      return truckTypesByCargoType(this.searchCriteria.cargoType);
    },
    unitBasePriceDate: {
      get(): string {
        return this.searchCriteria.unitBasePriceDate.datePrint();
      },
      set(s: string) {
        this.searchCriteria.unitBasePriceDate = new MomentX(s);
      }
    },
    cargoTypes(): RepositoryDto[] {
      return actorCargoTypes();
    }
  },
  methods: {
    money: money,
    currencyName: currencyName,
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    },
    fetchBaseIndexPeriods() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .baseIndexPeriods()
        .then(res => {
          this.baseIndexPeriods = res.map(
            r =>
              new RepositoryDto({
                id: r.indexDate.datePrint() as any,
                text: r.displayName
              })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSuppliers() {
      cached("suppliers", [this.searchCriteria.cargoType, true, true]).then(
        res => {
          this.suppliers = res;
        }
      );
    }
  },
  mounted() {
    this.fetchBaseIndexPeriods();
    this.fetchSuppliers();
  },
  data: (): {
    baseIndexPeriods: RepositoryDto[];
    suppliers: RepositoryDto[];
  } => ({
    baseIndexPeriods: [],
    suppliers: []
  })
});
