





























































































































import Vue from "vue";
import NotificationSettingsPickupFilterDialog from "@/components/web/settings/NotificationSettingsPickupFilterDialog.vue";
import NotificationSettingsDeliveryFilterDialog from "@/components/web/settings/NotificationSettingsDeliveryFilterDialog.vue";
import NotificationSettingsClientFilterDialog from "@/components/web/settings/NotificationSettingsClientFilterDialog.vue";
import NotificationSettingsUnitFilterDialog from "@/components/web/settings/NotificationSettingsUnitFilterDialog.vue";
import { NotificationNode } from "@/components/web/settings/NotificationSettings.vue";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    NotificationSettingsPickupFilterDialog,
    NotificationSettingsDeliveryFilterDialog,
    NotificationSettingsClientFilterDialog,
    NotificationSettingsUnitFilterDialog
  },
  props: {
    node: Object as () => NotificationNode,
    disabled: Boolean
  },
  computed: {
    pickupFilterText(): string {
      return `${t("PickupAreas")}: ${this.valueText([
        ...this.node.pickupZipCodeFilter!,
        ...this.node.pickupCountyFilter!
      ])}`;
    },
    deliveryFilterText(): string {
      return `${t("DeliveryAreas")}: ${this.valueText(
        this.node.deliveryZipCodeFilter!
      )}`;
    },
    clientFilterText(): string {
      return `${t("Clients")}: ${this.valueText(this.node.clientFilter!)}`;
    },
    unitFilterText(): string {
      return `${t("Units")}: ${this.valueText(this.node.unitFilter!)}`;
    }
  },
  methods: {
    valueText(values: any[]): string {
      if (values.length === 0) {
        return t("MobileAll");
      }
      return values.length.toString();
    },
    toggleAlteredByLoadUser() {
      this.node.alteredByLoadUser!.set(!this.node.alteredByLoadUser!.get());
    }
  },
  data: (): {
    pickupFilterDialog: boolean;
    deliveryFilterDialog: boolean;
    clientFilterDialog: boolean;
    unitFilterDialog: boolean;
  } => ({
    pickupFilterDialog: false,
    deliveryFilterDialog: false,
    clientFilterDialog: false,
    unitFilterDialog: false
  })
});
