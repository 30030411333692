
























import Vue from "vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import {
  ExplorerClient,
  ExplorerNodeDto,
  ApiConfiguration
} from "@/scripts/cld.api";
import objectToArray from "@/scripts/misc/objectToArray";
import { ExplorerApiMethod } from "@/components/support-admin/explorer/ExplorerDialog.vue";

export default Vue.extend({
  props: {
    item: ExplorerNodeDto,
    apiMethod: String as () => ExplorerApiMethod
  },
  components: {
    ReadOnlyText,
    Spinner
  },
  watch: {
    "item.guid": {
      immediate: true,
      handler() {
        if (this.item === undefined) {
          return;
        }
        let apiCall: Promise<any>;
        let apiClient: ExplorerClient = new ExplorerClient(
          new ApiConfiguration(this.$store)
        );
        switch (this.apiMethod) {
          case "loadhistory":
            apiCall = apiClient.loadhistory(this.item.id);
            break;
          case "visibility":
            apiCall = apiClient.visibility(this.item.id);
            break;
          case "resigns":
            apiCall = apiClient.resigns(this.item.id);
            break;
          case "suppliernotified":
            apiCall = apiClient.suppliernotified(this.item.id);
            break;
          case "supplierunbookedloads":
            apiCall = apiClient.supplierunbookedloads(this.item.id);
            break;
          case "agreement":
            apiCall = apiClient.agreement(this.item.id);
            break;
          case "agreementhistory":
            apiCall = apiClient.agreementhistory(this.item.id);
            break;
          case "agreementrow":
            apiCall = apiClient.agreementrow(this.item.id);
            break;
          case "agreementrowhistory":
            apiCall = apiClient.agreementrowhistory(this.item.id);
            break;
          case "agreementroute":
            apiCall = apiClient.agreementroute(this.item.id);
            break;
          case "agreementroutehistory":
            apiCall = apiClient.agreementroutehistory(this.item.id);
            break;
          case "agreementroutetrucktypes":
            apiCall = apiClient.agreementroutetrucktypes(this.item.id);
            break;
          case "location":
            console.error("Tycks inte va implementerad.");
          default:
            apiCall = new Promise<void>(resolve => resolve());
        }
        apiCall.then(res => {
          if (res !== undefined && res !== null) {
            if (Array.isArray(res)) {
              for (let i = 0; i < res.length; i++) {
                this.data.push(objectToArray(res[i]));
              }
            } else {
              this.data.push(objectToArray(res));
            }
          } else {
            this.data = [
              [
                ["OBS", "Det finns inga detaljer för den här typen av nod."],
                ["OBS", "Det kan dock läggas till i framtiden om så önskas."]
              ]
            ];
          }
          this.loading = false;
        });
      }
    }
  },
  data(): { loading: boolean; data: any[][][] } {
    return {
      loading: true,
      data: []
    };
  }
});
