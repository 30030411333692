var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding-top",attrs:{"elevation":"2","rounded":""}},[_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.filteredHeaders,"items":_vm.sortedRoutes,"custom-sort":_vm.customSort,"height":_vm.tableHeight,"loading":_vm.loading,"options":_vm.tableOptions,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [100, 500, -1],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"item-class":function (i) { return _vm.tracker.editingRoutes.some(function (r) { return r.id === i.id && r.edited; })
          ? 'blue-row'
          : ''; },"mobile-breakpoint":"0","disable-sort":"","fixed-header":"","hide-default-header":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var props = ref.props;
          var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.routeId",fn:function(){return [(!_vm.tracker.editingTab)?_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('RouteID')},model:{value:(_vm.filter.routeId),callback:function ($$v) {_vm.$set(_vm.filter, "routeId", $$v)},expression:"filter.routeId"}}):_vm._e()]},proxy:true},{key:"filter.pickup",fn:function(){return [(!_vm.tracker.editingTab)?_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationClient',
              pickup: true,
              cargoType: _vm.filter.cargoType
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.filter.pickupCounty,
              zipCode: _vm.filter.pickupZipcode,
              countryId: _vm.filter.pickupCountry
            }},on:{"input":function (val) {
                _vm.filter.pickupCounty = val.county;
                _vm.filter.pickupZipcode = val.zipCode;
                _vm.filter.pickupCountry = val.countryId;
              }}}):_vm._e()]},proxy:true},{key:"filter.delivery",fn:function(){return [(!_vm.tracker.editingTab)?_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationClient',
              pickup: false,
              cargoType: _vm.filter.cargoType
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.filter.deliveryCounty,
              zipCode: _vm.filter.deliveryZipcode,
              countryId: _vm.filter.deliveryCountry
            }},on:{"input":function (val) {
                _vm.filter.deliveryCounty = val.county;
                _vm.filter.deliveryZipcode = val.zipCode;
                _vm.filter.deliveryCountry = val.countryId;
              }}}):_vm._e()]},proxy:true},{key:"filter.currency",fn:function(){return [(!_vm.tracker.editingTab)?_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},model:{value:(_vm.filter.currency),callback:function ($$v) {_vm.$set(_vm.filter, "currency", $$v)},expression:"filter.currency"}}):_vm._e()]},proxy:true},{key:"filter.tag",fn:function(){return [(!_vm.tracker.editingTab)?_c('CustomFilter',{attrs:{"config":{ subtype: 'tag', cargoType: _vm.filter.cargoType },"placeholder":_vm.$t('Tag')},model:{value:(_vm.filter.tag),callback:function ($$v) {_vm.$set(_vm.filter, "tag", $$v)},expression:"filter.tag"}}):_vm._e()]},proxy:true},{key:"filter.procurementPeriods",fn:function(){return [(!_vm.tracker.editingTab)?_c('CustomFilter',{attrs:{"config":{ subtype: 'withWithout' },"placeholder":_vm.$t('Procurement')},model:{value:(_vm.filter.procurement),callback:function ($$v) {_vm.$set(_vm.filter, "procurement", $$v)},expression:"filter.procurement"}}):_vm._e()]},proxy:true},{key:"filter.hidden",fn:function(){return [_c('div',{staticClass:"small-padding-top tiny-padding-bottom"},[_c('CheckboxTiny',{attrs:{"value":_vm.allHidden},on:{"input":_vm.toggleAllHidden}})],1)]},proxy:true},{key:"filter.estimate",fn:function(){return [(!_vm.tracker.editingTab)?_c('CustomFilter',{attrs:{"config":{ subtype: 'withWithout' },"placeholder":_vm.$t('Estimation')},model:{value:(_vm.filter.estimate),callback:function ($$v) {_vm.$set(_vm.filter, "estimate", $$v)},expression:"filter.estimate"}}):_vm._e()]},proxy:true},{key:"filter.estimateHistory",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","small":"","color":"btn-cyan lighten-1","disabled":!_vm.routes.some(function (r) { return r.estimateFromHistoricalPeriod; })},on:{"click":_vm.applyAllEstimateHistory}},on),[_c('v-icon',{attrs:{"size":"23"}},[_vm._v("mdi-chevron-triple-left")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("ApplyHistoricalValues")))])])]},proxy:true},{key:"filter.specialRequirements",fn:function(){return [(!_vm.tracker.editingTab)?_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('SpecialRequirements')},model:{value:(_vm.filter.specReq),callback:function ($$v) {_vm.$set(_vm.filter, "specReq", $$v)},expression:"filter.specReq"}}):_vm._e()]},proxy:true},{key:"filter.customsInfoRequired",fn:function(){return [_c('div',{staticClass:"small-padding-top tiny-padding-bottom"},[_c('CheckboxTiny',{attrs:{"value":_vm.allCustomsInfoRequired},on:{"input":_vm.toggleAllCustomsInfoRequired}})],1)]},proxy:true},{key:"header.truckTypes",fn:function(){return [_c('AgreementRouteTableTruckTypeHeader',{attrs:{"cargoType":_vm.filter.cargoType}})]},proxy:true},{key:"filter.truckTypes",fn:function(){return [_c('div',{staticClass:"tiny-padding-top tiniest-margin-top"},[_c('AgreementRouteTableTruckTypeItems',{attrs:{"cargoType":_vm.filter.cargoType,"truckTypes":_vm.truckTypesForHeader,"indeterminates":_vm.indeterminatesForHeader},on:{"toggleTruckType":function (tt) { return _vm.allToggleTruckType(tt); }}})],1)]},proxy:true}],null,true)})]}},{key:"item.pickup",fn:function(ref){
              var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.pickup(item),"width":"165"}})]}},{key:"item.delivery",fn:function(ref){
              var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.delivery(item),"width":"170"}})]}},{key:"item.currency",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyName(item.currency))+" ")]}},{key:"item.tag",fn:function(ref){
              var item = ref.item;
return [(_vm.tagTabSelected)?_c('span',[_c('TextSingle',{attrs:{"only-on-blur":"","compact":"","max-length":10},on:{"input":function($event){return _vm.tagEdited([item.id])}},model:{value:(item.tag),callback:function ($$v) {_vm.$set(item, "tag", $$v)},expression:"item.tag"}})],1):_c('span',[_vm._v(" "+_vm._s(item.tag)+" ")])]}},{key:"item.procurementPeriods",fn:function(ref){
              var item = ref.item;
return [(item.procurementPeriods.length > 0)?_c('InfoTooltip',{attrs:{"text":_vm.procurementPeriods(item, true),"icon":"mdi-calendar-month"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.procurementPeriods(item))+" ")]}},{key:"item.agreementPeriods",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.agreementPeriods(item))+" "),(item.agreementPeriods.length > 1)?_c('InfoTooltip',{attrs:{"text":_vm.agreementPeriods(item, true),"icon":"mdi-calendar-month"}}):_vm._e()]}},{key:"item.specIcon",fn:function(ref){
              var item = ref.item;
return [_c('InfoTooltip',{attrs:{"text":item.specialRequirements,"icon":"mdi-exclamation-thick","color":"red"}})]}},{key:"item.deleteIcon",fn:function(ref){
              var item = ref.item;
return [(item.removeable)?_c('v-btn',{attrs:{"icon":"","height":"24","width":"24","loading":_vm.deleteInProgress === item.id,"disabled":_vm.deleteInProgress !== item.id && _vm.deleteInProgress > 0},on:{"click":function($event){return _vm.deleteAgreementRoute(item.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}},{key:"item.hidden",fn:function(ref){
              var item = ref.item;
return [_c('CheckboxTiny',{on:{"input":function($event){return _vm.hiddenEdited([item.id])}},model:{value:(item.hidden),callback:function ($$v) {_vm.$set(item, "hidden", $$v)},expression:"item.hidden"}}),(item.hidden)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-history ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.hiddenBy)+" "+_vm._s(item.hiddenWhen)+" ")]):_vm._e()]}},{key:"item.estimatedNoOfLoads",fn:function(ref){
              var item = ref.item;
return [(_vm.estimateEditable)?_c('NumberInput',{attrs:{"only-on-blur":"","allow-null":"","compact":""},on:{"input":function($event){return _vm.estimateEdited([item.id])}},model:{value:(item.estimatedNoOfLoads),callback:function ($$v) {_vm.$set(item, "estimatedNoOfLoads", $$v)},expression:"item.estimatedNoOfLoads"}}):_c('span',[_vm._v(" "+_vm._s(item.estimatedNoOfLoads)+" ")])]}},{key:"item.estimateHistory",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.estimateFromHistoricalPeriod)+" ")]}},{key:"item.specialRequirements",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"dont-render-newlines"},[_c('div',{staticStyle:{"width":"265px","display":"inline-block"}},[_c('TextMulti',{attrs:{"only-on-blur":"","compact":"","max-length":100,"rows":1},on:{"input":function($event){return _vm.specialRequirementsEdited([item.id])}},model:{value:(item.specialRequirements),callback:function ($$v) {_vm.$set(item, "specialRequirements", $$v)},expression:"item.specialRequirements"}})],1),(item.specialRequirementsBy)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-history ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.specialRequirementsBy)+" "+_vm._s(item.specialRequirementsWhen)+" ")]):_vm._e()],1)]}},{key:"item.customsInfoRequired",fn:function(ref){
              var item = ref.item;
return [_c('CheckboxTiny',{on:{"input":function($event){return _vm.customsInfoRequiredEdited([item.id])}},model:{value:(item.customsInfoRequired),callback:function ($$v) {_vm.$set(item, "customsInfoRequired", $$v)},expression:"item.customsInfoRequired"}})]}},{key:"item.truckTypes",fn:function(ref){
              var item = ref.item;
return [_c('AgreementRouteTableTruckTypeItems',{attrs:{"cargoType":_vm.filter.cargoType,"truckTypes":item.truckTypes,"indeterminates":[]},on:{"toggleTruckType":function (tt) { return _vm.toggleTruckType(item, tt); }}})]}}],null,true)}),_c('AgreementRouteEstimateHistoryDialog',{attrs:{"dialog":_vm.estimateHistoryDialog,"cargoType":_vm.filter.cargoType},on:{"close":function($event){_vm.estimateHistoryDialog = false},"fetch":_vm.fetchEstimateHistory}}),_c('AgreementRouteTagDialog',{attrs:{"dialog":_vm.tagDialog},on:{"close":function($event){_vm.tagDialog = false},"update":function (v) { return _vm.applyAllTag(v); }}}),_c('AgreementRouteSpecReqDialog',{attrs:{"dialog":_vm.specReqDialog},on:{"close":function($event){_vm.specReqDialog = false},"update":function (v) { return _vm.applyAllSpecialRequirements(v); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }