













































































































import Vue from "vue";
import ifNotTabbing from "@/scripts/misc/ifNotTabbing";
import {
  ApiConfiguration,
  StandardPhraseDto,
  StandardPhrasesClient,
  StandardPhraseType
} from "@/scripts/cld.api";
import { popupDialog } from "@/scripts/misc/popupDialogs";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SearchCard,
    Autocomplete,
    TextMulti,
    TextSingle
  },
  props: {
    value: String,
    label: String,
    type: String,
    tabindex: String,
    top: Boolean,
    disabled: Boolean
  },
  watch: {
    menu() {
      //Prevents flickering
      this.closable = false;
      setTimeout(() => {
        this.closable = true;
      }, 50);
    },
    presetId() {
      this.populateFromPreset();
    }
  },
  computed: {
    summary(): string | null {
      if (!this.value) {
        return null;
      }
      return this.value.replaceAll("\n", "  ");
    },
    phraseType(): StandardPhraseType {
      switch (this.type) {
        case "note":
          return StandardPhraseType.Note;
        case "pickupInfo":
          return StandardPhraseType.PickupInfo;
        case "destinationInfo":
          return StandardPhraseType.DestinationInfo;
        case "contentInfo":
          return StandardPhraseType.ContentInfo;
        default:
          throw new Error(`Invalid standardphrase type ${this.type}`);
      }
    },
    phrase(): StandardPhraseDto | undefined {
      return this.phrases.find(p => p.id === this.presetId);
    }
  },
  methods: {
    ifNotTabbing: ifNotTabbing,
    keydown(key: any) {
      this.menu = true;
      if (!!this.value) {
        key = this.value + key;
      }
      this.input(key);
    },
    input(v?: string) {
      this.$emit("input", v);
    },
    clickOutside(event: any) {
      if (this.skipCloseOutsideOnce) {
        this.skipCloseOutsideOnce = false;
        return;
      }
      if (!event.target.className.includes("v-list")) {
        this.closeMenu();
      }
    },
    closeMenu() {
      if (!this.closable || !this.menu) {
        return;
      }
      this.menu = false;
      this.$nextTick(() => (this.$refs.summary as any).focus());
    },
    invalid(v?: string): boolean {
      return !this.trim(v);
    },
    trim(v?: string): string | undefined {
      if (!v) {
        return undefined;
      }
      return v.trim();
    },
    populateFromPreset() {
      if (this.phrase) {
        this.input(this.phrase.body);
      }
    },
    deletePreset() {
      if (this.presetId) {
        this.skipCloseOutsideOnce = true;
        popupDialog({
          title: t("Delete"),
          body: t("ConfirmRemoveText"),
          btnText1: t("Delete"),
          btnColor1: "error",
          btnCallback1: this.deletePresetCallback
        });
      }
    },
    deletePresetCallback() {
      new StandardPhrasesClient(new ApiConfiguration(this.$store))
        .delete(this.presetId!)
        .then(() => {
          this.presetId = undefined;
          this.fetchStandardPhrases();
          this.input(undefined);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    newPreset() {
      this.presetId = undefined;
      this.editSubject = "";
      this.editing = true;
    },
    editPreset() {
      this.editSubject = this.phrase!.subject!;
      this.editing = true;
    },
    discardEditPreset() {
      this.populateFromPreset();
      this.editing = false;
    },
    commitEditPreset() {
      new StandardPhrasesClient(new ApiConfiguration(this.$store))
        .edit(
          new StandardPhraseDto({
            id: this.presetId === undefined ? 0 : this.presetId,
            phraseType: this.phraseType,
            subject: this.trim(this.editSubject),
            body: this.trim(this.value)
          })
        )
        .then(() => {
          return this.fetchStandardPhrases();
        })
        .then(() => {
          if (!this.presetId && this.phrases.length > 0) {
            //Highest id = the one we just added.
            this.presetId = this.phrases
              .map(p => p.id)
              .reduce((max: number, curr) => Math.max(max, curr));
          }
          this.editing = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    async fetchStandardPhrases(): Promise<void> {
      return new StandardPhrasesClient(new ApiConfiguration(this.$store))
        .list()
        .then(res => {
          this.phrases = res.filter(p => p.phraseType === this.phraseType);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchStandardPhrases();
  },
  data: (): {
    menu: boolean;
    phrases: StandardPhraseDto[];
    closable: boolean;
    presetId?: number;
    editing: boolean;
    editSubject: string;
    skipCloseOutsideOnce: boolean;
  } => ({
    menu: false,
    phrases: [],
    closable: true,
    presetId: undefined,
    editing: false,
    editSubject: "",
    skipCloseOutsideOnce: false
  })
});
