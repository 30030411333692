




































































































































import Vue from "vue";
import loadListHeaders from "@/scripts/misc/loadListHeaders";
import {
  Container as drop,
  Draggable as drag,
  DropResult
} from "vue-smooth-dnd";

import {
  ActorType,
  ApiConfiguration,
  UserConfigurationType,
  UsersClient
} from "@/scripts/cld.api";
import { LoadListTab, TableHeader } from "@/scripts/types";
import { actions, gets } from "@/scripts/store/constants";
import ContentContainer from "@/components/shared/ui/ContentContainer.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";

export default Vue.extend({
  components: {
    ContentContainer,
    Dropdown,
    drop,
    drag
  },
  props: {
    dialog: Boolean,
    selectedTab: LoadListTab,
    tabs: Array,
    selectedTabIndex: Number
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.save(false);
        }
      },
      immediate: true
    }
  },
  computed: {
    confHeaders(): TableHeader[] {
      return loadListHeaders(
        this.$store.getters[gets.userInfo].actorType,
        true,
        this.selectedTab.headers
      );
    },
    remainingHeaders(): TableHeader[] {
      return loadListHeaders(
        this.$store.getters[gets.userInfo].actorType,
        true
      ).filter(h1 => !this.confHeaders.some(h2 => h2.id === h1.id));
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  methods: {
    handleDrop(row: string, drop: DropResult) {
      if (drop.removedIndex === drop.addedIndex) {
        return;
      }
      if (row === "top") {
        if (drop.removedIndex !== null) {
          this.selectedTab.headers.splice(drop.removedIndex, 1);
        }
        if (drop.addedIndex !== null) {
          this.selectedTab.headers.splice(drop.addedIndex, 0, drop.payload.id);
        }
      }
    },
    save(close: boolean) {
      this.saveInProgress = true;
      const promises = [];
      for (const tab of this.tabs as LoadListTab[]) {
        promises.push(
          this.$store.dispatch(actions.saveUserConf, {
            type: UserConfigurationType.LoadTab,
            subTypeId: tab.id
          })
        );
      }
      Promise.all(promises)
        .then(() => {
          if (close) {
            return this.close();
          }
        })
        .then(() => {
          this.saveInProgress = false;
        });
    },
    cancel() {
      this.cancelInProgress = true;
      this.close().then(() => {
        this.cancelInProgress = false;
      });
    },
    async close(): Promise<void> {
      return new UsersClient(new ApiConfiguration(this.$store))
        .profile()
        .then(res => {
          return this.$store.dispatch(actions.initUserConf, {
            actorType: this.actorType,
            confs: res.userConfigurations
          });
        })
        .then(() => {
          this.$emit("close");
        });
    },
    resetTab() {
      this.$store.dispatch(actions.resetUserConf, {
        type: UserConfigurationType.LoadTab,
        subTypeId: this.selectedTab.id,
        actorType: this.actorType
      });
    }
  },
  data: (): { saveInProgress: boolean; cancelInProgress: boolean } => ({
    saveInProgress: false,
    cancelInProgress: false
  })
});
