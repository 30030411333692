





















































































































































































import Vue from "vue";
import { stringCompare } from "@/scripts/misc/stringUtils";
import {
  SupplierAgreementContainerDto,
  SupplierAgreementRowDto,
  SupplierAgreementSearchCriteria,
  CargoType,
  UserConfigurationType,
  AgreementSupplierOrderBy
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { gets } from "@/scripts/store/constants";
import { indexDateTooltip } from "../../loads/client/accordion/AccordionPrices.vue";
import money, { percentage } from "@/scripts/misc/money";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    cargoType: Number,
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    agreementContainer: SupplierAgreementContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 266);
    },
    searchCriteria(): SupplierAgreementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierAgreement,
        this.cargoType
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        {
          value: "pickupLocation",
          text: "PickupLocation",
          sortValue: AgreementSupplierOrderBy.PickupLocation,
          width: 190
        },
        {
          value: "deliveryLocation",
          text: "DeliveryLocation",
          sortValue: AgreementSupplierOrderBy.DeliveryLocation,
          width: 200
        },
        {
          value: "specialRequirements",
          text: "",
          untranslated: true,
          width: 1
        },
        {
          value: "agreementPeriod",
          text: "AgreementPeriod",
          sortValue: AgreementSupplierOrderBy.AgreementPeriod,
          width: 1
        },
        {
          value: "distance",
          text: "Kilometer",
          sortValue: AgreementSupplierOrderBy.Distance,
          width: 1
        },
        {
          value: "currencyName",
          text: "Currency",
          sortValue: AgreementSupplierOrderBy.Currency,
          width: 70
        },
        {
          value: "truckTypeName",
          text: "TruckType",
          sortValue: AgreementSupplierOrderBy.TruckType,
          width: 120
        },
        {
          value: "ranking",
          text: "Ranking",
          sortValue: AgreementSupplierOrderBy.Ranking,
          width: 1
        }
      ];
      if (this.searchCriteria.unitBasePrice) {
        headers.push({
          value: "unitBasePrice",
          text: "UnitBasePrice",
          sortValue: AgreementSupplierOrderBy.Price,
          width: 65
        });
      } else {
        headers.push({
          value: "basePrice",
          text: "AgreedPrice",
          sortValue: AgreementSupplierOrderBy.Price,
          width: 65
        });
      }
      switch (this.cargoType) {
        case CargoType.Road:
          headers.push({
            value: "invoicePrice",
            text: "InvoicePrices",
            sortValue: AgreementSupplierOrderBy.Price,
            width: 65
          });
          headers.push({
            value: "stopPrice",
            text: "StopPrice",
            width: 1
          });
          headers.push({
            value: "baseIndexName",
            text: "BaseIndex",
            sortValue: AgreementSupplierOrderBy.BaseIndexDate,
            width: 1
          });
          break;
        case CargoType.Container:
          headers.push({
            value: "serviceFee",
            text: "ServiceFee",
            width: 1
          });
          break;
        case CargoType.MachineTransport:
          headers.push({
            value: "initialFee",
            text: "TruckTypeFee",
            width: 1
          });
          headers.push({
            value: "stopPrice",
            text: "StopPrice",
            width: 1
          });
          break;
      }
      headers.push({ value: "note", text: "Note", width: 1 });
      return headers;
    }
  },
  methods: {
    money: money,
    percentage: percentage,
    search() {
      this.$emit("search");
    },
    customGroup(
      items: SupplierAgreementRowDto[]
    ): { name: string; sortVal: string; items: SupplierAgreementRowDto[] }[] {
      let res: {
        name: string;
        sortVal: string;
        items: SupplierAgreementRowDto[];
      }[] = [];
      for (let ar of items) {
        const matchingGroup = res.find(x => x.name === ar.clientName!);
        if (matchingGroup) {
          matchingGroup.items.push(ar);
        } else {
          res.push({
            name: ar.clientName!,
            sortVal: ar.clientName!,
            items: [ar]
          });
        }
      }
      res.sort((a, b) => {
        return stringCompare(a.sortVal, b.sortVal);
      });
      return res;
    },
    pickupText(item: SupplierAgreementRowDto): string {
      return `${item.pickupCounty}, ${item.pickupZipcode}, ${item.pickupCountryISO}`;
    },
    deliveryText(item: SupplierAgreementRowDto): string {
      return `${item.deliveryCounty}, ${item.deliveryZipcode}, ${item.deliveryCountryISO}`;
    },
    indexTooltip(item: SupplierAgreementRowDto): string {
      return indexDateTooltip(
        item.indexFactor,
        item.baseIndexName!,
        this.searchCriteria.date
      );
    },
    goToIndexes(item: SupplierAgreementRowDto) {
      window.open(
        `/indexes?from=${item.baseIndexDate.datePrint()}&to=${this.searchCriteria.date.datePrint()}`,
        "_blank"
      );
    }
  }
});
