import { ActorType, LoadOrderBy } from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";

export default (
  actorType: ActorType,
  forEdit: boolean,
  userConfig?: number[]
): TableHeader[] => {
  let res = availableHeaders();
  if (userConfig !== undefined) {
    res = userConfig
      .map(id => res.find(r => r.id === id))
      .filter(h => h !== undefined) as LoadListTableHeaderInternal[];
  }
  if (!forEdit) {
    res = [...nonEditableHeaders, ...res];
  }
  return res.filter(h => !h.blacklist || !h.blacklist.includes(actorType));
};

const availableHeaders = (): LoadListTableHeaderInternal[] => {
  return headers.map(
    h =>
      ({
        sortValue: h.id! > 0 ? h.id : undefined,
        ...h
      } as LoadListTableHeaderInternal)
  );
};

interface LoadListTableHeaderInternal extends TableHeader {
  blacklist?: ActorType[];
}

const nonEditableHeaders: LoadListTableHeaderInternal[] = [
  {
    id: -100,
    value: "dots",
    width: 38
  },
  {
    id: -101,
    value: "infoIcons",
    width: 1
  }
];

const headers: LoadListTableHeaderInternal[] = [
  {
    id: LoadOrderBy.Id,
    value: "id",
    text: "LoadID",
    width: 90
  },
  {
    id: LoadOrderBy.TransportNo,
    value: "transportNo",
    text: "TransportNo",
    width: 1
  },
  {
    id: LoadOrderBy.StatusName,
    value: "statusName",
    text: "Status",
    width: 1
  },
  {
    id: LoadOrderBy.TruckTypeName,
    value: "truckTypeName",
    text: "TruckType",
    width: 1
  },
  {
    blacklist: [ActorType.Client],
    id: LoadOrderBy.ClientName,
    value: "clientName",
    text: "Client",
    width: 1
  },
  {
    blacklist: [ActorType.Supplier],
    id: LoadOrderBy.SupplierName,
    value: "supplierName",
    text: "Supplier",
    width: 1
  },
  {
    blacklist: [ActorType.Customer],
    id: LoadOrderBy.CustomerName,
    value: "customerName",
    text: "Customer",
    width: 1
  },
  {
    blacklist: [ActorType.Customer, ActorType.Terminal],
    id: LoadOrderBy.TerminalName,
    value: "terminalName",
    text: "Terminal",
    width: 1
  },
  {
    id: LoadOrderBy.PickupLocationFormatted,
    value: "pickupLocationFormatted",
    text: "PickupLocation",
    width: 1
  },
  {
    id: LoadOrderBy.DeliveryLocationFormatted,
    value: "deliveryLocationFormatted",
    text: "DeliveryLocation",
    width: 1
  },
  {
    id: LoadOrderBy.RegDate,
    value: "regDate",
    text: "RegDate",
    width: 125
  },
  {
    id: LoadOrderBy.SpotTenderEndDate,
    value: "spotTenderEndDate",
    text: "SpotTenderEndDateShort",
    width: 125
  },
  {
    id: LoadOrderBy.PickupEarliest,
    value: "pickupEarliest",
    text: "PickupEarliest",
    width: 125
  },
  {
    id: LoadOrderBy.PickupLatest,
    value: "pickupLatest",
    text: "PickupLatest",
    width: 125
  },
  {
    id: LoadOrderBy.DeliveryEarliest,
    value: "deliveryEarliest",
    text: "DeliveryEarliest",
    width: 125
  },
  {
    id: LoadOrderBy.DeliveryLatest,
    value: "deliveryLatest",
    text: "DeliveryLatest",
    width: 125
  },
  {
    id: LoadOrderBy.BookingDate,
    value: "bookingDate",
    text: "BookingDate",
    width: 125
  },
  {
    id: LoadOrderBy.LoadedDate,
    value: "loadedDate",
    text: "LoadedDate",
    width: 125
  },
  {
    id: LoadOrderBy.BookedPickupDate,
    value: "bookedPickupDate",
    text: "BookedPickupDate",
    width: 125
  },
  {
    id: LoadOrderBy.DeliveryDate,
    value: "deliveryDate",
    text: "DeliveredDate",
    width: 125
  },
  {
    id: LoadOrderBy.BookedDeliveryDate,
    value: "bookedDeliveryDate",
    text: "BookedDeliveryDate",
    width: 125
  },
  {
    id: LoadOrderBy.AlterDate,
    value: "alterDate",
    text: "Altered",
    width: 125
  },
  {
    blacklist: [ActorType.Customer, ActorType.Terminal],
    id: LoadOrderBy.Unit,
    value: "unit",
    text: "Unit",
    width: 1
  },
  {
    id: LoadOrderBy.ReferenceNo,
    value: "referenceNo",
    text: "ReferenceNo",
    width: 1
  },
  {
    id: LoadOrderBy.RegNo,
    value: "regNo",
    text: "TruckNo",
    width: 80
  },
  {
    id: LoadOrderBy.BookingNo,
    value: "bookingNo",
    text: "BookingNo",
    width: 1
  },
  {
    blacklist: [ActorType.Customer, ActorType.Terminal],
    id: LoadOrderBy.NumExtraStops,
    value: "numExtraStops",
    text: "Stop",
    width: 1
  },
  {
    blacklist: [ActorType.Supplier, ActorType.Customer, ActorType.Terminal],
    id: LoadOrderBy.DeviationCause,
    value: "deviationCause",
    text: "DeviationCause",
    width: 1
  },
  {
    blacklist: [ActorType.Supplier, ActorType.Customer, ActorType.Terminal],
    id: LoadOrderBy.OtherPriceCause,
    value: "otherPriceCause",
    text: "OtherPriceCause",
    width: 1
  },
  {
    blacklist: [ActorType.Client, ActorType.Supplier, ActorType.Customer],
    id: LoadOrderBy.NumRelatedLoads,
    value: "numRelatedLoads",
    text: "RelatedLoads",
    width: 1
  },
  {
    blacklist: [ActorType.Client, ActorType.Customer, ActorType.Terminal],
    id: LoadOrderBy.VisibleToNextSupplier,
    value: "visibleToNextSupplier",
    text: "VisibleForNextSupplierShort",
    width: 1
  },
  {
    blacklist: [ActorType.Customer, ActorType.Terminal],
    id: LoadOrderBy.BookedPickupInfo,
    value: "bookedPickupInfo",
    text: "BookedPickupInfo",
    width: 1
  }
];
