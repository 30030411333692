














































import Vue from "vue";
import { LoadListTab } from "@/scripts/types";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import MenuButton, {
  MenuButtonOption
} from "@/components/shared/input/MenuButton.vue";
import LoadListSearch from "@/components/web/loads/shared/list/LoadListSearch.vue";
import {
  ActorType,
  CargoType,
  LoadActionPermission,
  LoadSearchCriteriaDto,
  OrderType,
  UserLevel,
  UserConfigurationType
} from "@/scripts/cld.api";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { cargoTypeIcon } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    MenuButton,
    LoadListSearch
  },
  props: {
    tabs: Array as () => LoadListTab[],
    selectedTab: LoadListTab,
    loading: Boolean
  },
  watch: {
    "$route.query": {
      handler() {
        const query = { ...this.$route.query };
        const tab = query.tab ? Number(query.tab) : undefined;
        if (!tab) {
          return;
        }
        delete query.tab;
        this.$emit("selectTabId", tab);
        this.$router.push({
          query: query,
          path: this.$route.path
        });
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      return `${this.$t("LoadList")} - ${this.tabName(this.selectedTab)}`;
    },
    selectedTabIndex: {
      get(): number {
        return (this.tabs as LoadListTab[]).findIndex(
          t => t.id === this.selectedTab.id
        );
      },
      set(index: number) {
        this.$emit("selectTabId", (this.tabs as LoadListTab[])[index].id);
      }
    },
    cargoTypes(): MenuButtonOption[] {
      return actorCargoTypes().map(x => ({ ...x, icon: cargoTypeIcon(x.id) }));
    },
    actorCargoTypes(): CargoType[] {
      return this.$store.getters[gets.userInfo].actorCargoTypes;
    },
    client(): boolean {
      return this.$store.getters[gets.userInfo].actorType === ActorType.Client;
    },
    viewer(): boolean {
      return this.$store.getters[gets.userInfo].userLevel === UserLevel.Viewer;
    },
    searchCriteria(): LoadSearchCriteriaDto {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.LoadList,
        this.selectedTab.id
      );
    }
  },
  methods: {
    clearSelection() {
      this.$emit("clearSelection");
    },
    createNewLoad(orderType: OrderType) {
      this.$router.push({
        path: this.$route.path,
        query: {
          action: LoadActionPermission.ClientEdit.toString(),
          ordertype: orderType.toString()
        }
      });
    },
    tabName(tab: LoadListTab): string {
      return tab.custom ? tab.name : this.$t(tab.name).toString();
    }
  }
});
