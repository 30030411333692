var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"persistent":"","scrollable":"","width":_vm.width}},[_c('v-card',{class:{
      'full-height-dialog': _vm.fullHeight,
      'semi-full-height-dialog': _vm.semiFullHeight
    }},[_c('v-card-title',{staticClass:"headline",class:_vm.warning ? 'warning lighten-1' : 'card-cyan'},[_c('div',{staticClass:"medium-margin-right"},[_vm._v(_vm._s(_vm.title))]),_vm._t("head-left"),_c('v-spacer'),_vm._t("head-right"),(!_vm.noCloseButton)?_c('v-btn',{attrs:{"large":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close")])],1):_vm._e()],2),_c('v-card-text',{class:{
        'no-padding-bottom': _vm.noPaddingBottom,
        'no-padding-sides': _vm.noPaddingSides && !_vm.loading,
        'background-pale': _vm.grey
      }},[(_vm.loading)?_c('div',{class:{ 'loader-container': !_vm.fullHeight && !_vm.semiFullHeight }},[_c('Spinner',{attrs:{"size":150,"top-margin":_vm.fullHeight || _vm.semiFullHeight,"small-top-margin":!_vm.fullHeight && !_vm.semiFullHeight}})],1):_vm._t("body")],2),_c('v-card-actions',{class:{ 'background-pale': _vm.grey }},[_vm._t("actions")],2),_vm._t("dialogs")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }