

















import Vue from "vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import AutocompleteMultiCountry from "@/components/shared/input/AutocompleteMultiCountry.vue";
import { CargoType, RepositoryDto } from "@/scripts/cld.api";
import { cargoTypes } from "@/scripts/misc/enumLists";
import { cargoTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    AutocompleteMultiCountry,
    TextMulti
  },
  methods: {
    cargoTypeName: cargoTypeName
  },
  data: (): {
    text: string;
    cargoTypeOptions: RepositoryDto[];
    cargoTypes: CargoType[];
  } => ({
    text: "Lek inte på jobbet",
    cargoTypeOptions: cargoTypes(),
    cargoTypes: []
  })
});
