





























































































































































































































































import Vue from "vue";
import { AgreementDataDtoX, TableHeader, TableOptions } from "@/scripts/types";
import {
  CargoType,
  ClientAgreementSearchCriteria,
  UserConfigurationType
} from "@/scripts/cld.api";
import { stringCompare } from "@/scripts/misc/stringUtils";
import ProcurementTableExpansion from "@/components/web/agreements/client/ProcurementTableExpansion.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import { gets } from "@/scripts/store/constants";
import { indexDateTooltip } from "../../loads/client/accordion/AccordionPrices.vue";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  components: {
    ProcurementTableExpansion,
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem,
    CheckboxTiny
  },
  props: {
    cargoTypeId: Number as () => CargoType,
    loading: Boolean,
    agreements: Array as () => AgreementDataDtoX[],
    loadingText: String,
    tableOptions: Object as () => TableOptions,
    selectedAgreements: Array,
    expandedAgreements: Array
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 375);
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    searchCriteria(): ClientAgreementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ClientProcurement,
        this.cargoTypeId
      );
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({ value: "actions", customHeader: true, width: 100 });
      headers.push({
        value: "pickupLocation",
        text: "AgreementRoutePickupLocation",
        sortValue: true,
        width: 180
      });
      headers.push({
        value: "deliveryLocation",
        text: "AgreementRouteDeliveryLocation",
        sortValue: true,
        width: 200
      });
      headers.push({
        value: "tag",
        text: "Tag",
        sortValue: true,
        width: 110
      });
      headers.push({
        value: "agreementPeriod",
        text: "AgreementPeriod",
        sortValue: true,
        width: 1
      });
      headers.push({
        value: "procurementPeriod",
        text: "ProcurementPeriod",
        sortValue: true,
        width: 1
      });
      if (this.cargoTypeId === CargoType.Road) {
        headers.push({
          value: "baseIndexName",
          text: "BaseIndex",
          sortValue: true,
          width: 1
        });
      }
      headers.push({
        value: "estimatedNoOfLoads",
        text: "NumberOfEstimatedLoads",
        sortValue: true,
        width: 1
      });
      headers.push({
        value: "currencyName",
        text: "Currency",
        sortValue: true,
        width: 80
      });
      return headers;
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    expand(item: AgreementDataDtoX) {
      this.$emit("agreementExpanded", item);
    },
    select(item: AgreementDataDtoX) {
      const index = this.selectedAgreements.indexOf(item);
      this.itemSelected({ item: item, value: index === -1 });
    },
    itemSelected(event: { item: AgreementDataDtoX; value: boolean }) {
      this.$emit("selectedAgreementsChanged", event.value, [event.item]);
    },
    allItemsSelected(event: { items: AgreementDataDtoX[]; value: boolean }) {
      this.$emit("selectedAgreementsChanged", event.value, event.items);
    },
    unselectAllItems() {
      this.$emit("selectedAgreementsChanged", false, this.agreements);
    },
    openRankingDialog(agreementId: number) {
      this.$emit("openRankingDialog", agreementId);
    },
    pickup(item: AgreementDataDtoX): string {
      return (
        item.pickupCounty +
        ", " +
        item.pickupZipcode +
        ", " +
        item.pickupCountry
      );
    },
    delivery(item: AgreementDataDtoX): string {
      return (
        item.deliveryCounty +
        ", " +
        item.deliveryZipcode +
        ", " +
        item.deliveryCountry
      );
    },
    customSort(
      items: AgreementDataDtoX[],
      sortByArr: string[],
      sortDescArr: boolean[]
    ) {
      let c: { (a: AgreementDataDtoX, b: AgreementDataDtoX): number };
      const sortBy = sortByArr[0];
      const sortDesc = sortDescArr[0];
      switch (sortBy) {
        case "pickupLocation":
          c = (a, b) => stringCompare(this.pickup(a), this.pickup(b));
          break;
        case "deliveryLocation":
          c = (a, b) => stringCompare(this.delivery(a), this.delivery(b));
          break;
        case "procurementPeriod":
          c = (a, b) =>
            stringCompare(
              a.procurementFromDate.unix(),
              b.procurementFromDate.unix()
            ) ||
            stringCompare(
              a.procurementToDate.unix(),
              b.procurementToDate.unix()
            );
          break;
        case "agreementPeriod":
          c = (a, b) =>
            stringCompare(a.fromDate.unix(), b.fromDate.unix()) ||
            stringCompare(a.toDate.unix(), b.toDate.unix());
          break;
        default:
          c = (a, b) => stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      }
      items = items.sort(
        (a, b) =>
          (sortDesc ? -1 : 1) * c(a, b) ||
          stringCompare(this.pickup(a), this.pickup(b)) ||
          stringCompare(this.delivery(a), this.delivery(b))
      );
      return items;
    },
    indexTooltip(item: AgreementDataDtoX): string {
      return indexDateTooltip(
        item.indexFactor,
        item.baseIndexName!,
        new MomentX().startOfDay()
      );
    },
    goToIndexes(item: AgreementDataDtoX) {
      window.open(
        `/indexes?from=${item.baseIndexDate.datePrint()}&to=${this.searchCriteria.date.datePrint()}`,
        "_blank"
      );
    }
  }
});
