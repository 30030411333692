/* ALLT ANVÄNDANDE AV MOMENT SKA GÅ GENOM DENNA KLASS */
import momentBase from "moment";

const minDate = "2000-01-01 00:00:00";
const maxDate = "2049-12-31 23:59:59";

type MomentXInput = momentBase.Moment | string | undefined | null;

class MomentX {
  private m: momentBase.Moment;

  constructor(input?: MomentXInput, format?: string) {
    this.m = momentBase(input, format).utc(true);
    if (this.m < momentBase(minDate).utc(true)) {
      this.m = momentBase(minDate).utc(true);
    } else if (this.m > momentBase(maxDate).utc(true)) {
      this.m = momentBase(maxDate).utc(true);
    }
  }

  private mCopy(): momentBase.Moment {
    //Löser problem med att moment ibland är mutable. Vi vill inte ha det så.
    return momentBase(this.m).utc(true);
  }
  private mCopyOther(d: MomentX): momentBase.Moment {
    return momentBase(d.m).utc(true);
  }
  dayPrint(locale: string): string {
    return this.mCopy()
      .locale(locale)
      .format("dddd");
  }
  shortDayPrint(locale: string): string {
    return this.mCopy()
      .locale(locale)
      .format("ddd");
  }
  monthNamePrint(locale: string): string {
    return this.mCopy()
      .locale(locale)
      .format("MMMM");
  }
  quarterPrint(): string {
    return "Q" + this.mCopy().quarter();
  }
  yearMonthNamePrint(locale: string): string {
    return this.mCopy()
      .locale(locale)
      .format("YYYY MMMM");
  }
  yearQuarterPrint(): string {
    return this.yearPrint() + " " + this.quarterPrint();
  }
  yearPrint(): string {
    return this.mCopy().format("YYYY");
  }
  datePrint(): string {
    return this.mCopy().format("YYYY-MM-DD");
  }
  timePrint(): string {
    return this.mCopy().format("HH:mm:ss");
  }
  shortTimePrint(): string {
    return this.mCopy().format("HH:mm");
  }
  dateTimePrint(): string {
    return this.mCopy().format("YYYY-MM-DD HH:mm:ss");
  }
  dateTextPrint(locale: string): string {
    return this.mCopy()
      .locale(locale)
      .format("ddd D MMMM");
  }
  shortDateTextPrint(locale: string): string {
    return this.mCopy()
      .locale(locale)
      .format("D MMM");
  }
  shortDateTimePrint(): string {
    return this.mCopy().format("YYYY-MM-DD HH:mm");
  }
  shortDateTimePrintSeparated(): string {
    return this.mCopy().format("YYYY-MM-DD  HH:mm");
  }
  shortDateTimePrintWithTo(d: MomentX, locale: string): string {
    return `${d.shortDateTimePrint()}  (${this.to(d, locale)})`;
  }
  to(d: MomentX, locale: string): string {
    return this.mCopy()
      .locale(locale)
      .to(this.mCopyOther(d));
  }
  minutes(): number {
    return this.mCopy().minutes();
  }
  hours(): number {
    return this.mCopy().hours();
  }
  date(): number {
    return this.mCopy().date();
  }
  month(): number {
    return this.mCopy().month();
  }
  year(): number {
    return this.mCopy().year();
  }
  withHours(hours: number): MomentX {
    return new MomentX(this.mCopy().hours(hours));
  }
  withMinutes(minutes: number): MomentX {
    return new MomentX(this.mCopy().minutes(minutes));
  }
  startOfDay(): MomentX {
    return new MomentX(this.mCopy().startOf("day"));
  }
  endOfDay(): MomentX {
    return new MomentX(this.mCopy().endOf("day"));
  }
  startOfMonth(): MomentX {
    return new MomentX(this.mCopy().startOf("month"));
  }
  endOfMonth(): MomentX {
    return new MomentX(this.mCopy().endOf("month")).endOfDay();
  }
  startOfYear(): MomentX {
    return new MomentX(this.mCopy().startOf("year"));
  }
  endOfYear(): MomentX {
    return new MomentX(this.mCopy().endOf("year")).endOfDay();
  }
  addSeconds(amount: number): MomentX {
    return new MomentX(this.mCopy().add(amount, "seconds"));
  }
  addMinutes(amount: number): MomentX {
    return new MomentX(this.mCopy().add(amount, "minutes"));
  }
  addHours(amount: number): MomentX {
    return new MomentX(this.mCopy().add(amount, "hours"));
  }
  addDays(amount: number): MomentX {
    return new MomentX(this.mCopy().add(amount, "days"));
  }
  addMonths(amount: number): MomentX {
    return new MomentX(this.mCopy().add(amount, "months"));
  }
  addYears(amount: number): MomentX {
    return new MomentX(this.mCopy().add(amount, "years"));
  }
  loadingSlotNowish(): MomentX {
    const d = this.mCopy()
      .startOf("minute")
      .add(8, "minute");
    return new MomentX(d).withMinutes(d.minutes() - (d.minutes() % 5));
  }
  isBetween(date1: MomentX, date2: MomentX) {
    return this.isSameOrAfter(date1) && this.isSameOrBefore(date2);
  }
  isSame(date: MomentX): boolean {
    return this.mCopy().unix() === date.unix();
  }
  isBefore(date: MomentX): boolean {
    return this.mCopy().unix() < date.unix();
  }
  isSameOrBefore(date: MomentX): boolean {
    return this.mCopy().unix() <= date.unix();
  }
  isAfter(date: MomentX): boolean {
    return this.mCopy().unix() > date.unix();
  }
  isSameOrAfter(date: MomentX): boolean {
    return this.mCopy().unix() >= date.unix();
  }
  isLessThanSecondsOld(seconds: number): boolean {
    return this.unix() > new MomentX().unix() - seconds;
  }
  isValid(): boolean {
    return this.mCopy().isValid();
  }
  toISOString(keepOffset?: boolean): string {
    return this.mCopy().toISOString(keepOffset);
  }
  toJSON(): string {
    return this.mCopy().toJSON();
  }
  toString(): string {
    return this.shortDateTimePrint();
  }
  unix(): number {
    return this.mCopy().unix();
  }
  toDuration(): MomentX.Duration {
    return new DurationX(
      this.mCopy().hours() * 3600 * 1000 + this.mCopy().minutes() * 60 * 1000
    );
  }
}

type DurationXInput = momentBase.Duration | number | undefined | null;

class DurationX {
  private d: momentBase.Duration;

  constructor(input?: DurationXInput) {
    this.d = momentBase.duration(input);
  }

  private dCopy(): momentBase.Duration {
    //Löser problem med att moment ibland är mutable. Vi vill inte ha det så.
    return momentBase.duration(this.d);
  }

  format(_: any, __: any): string {
    return (
      this.dCopy().days() +
      "." +
      this.dCopy()
        .hours()
        .toString()
        .padStart(2, "0") +
      ":" +
      this.dCopy()
        .minutes()
        .toString()
        .padStart(2, "0") +
      ":" +
      this.dCopy()
        .seconds()
        .toString()
        .padStart(2, "0") +
      "." +
      this.dCopy()
        .milliseconds()
        .toString()
        .padStart(3, "0")
    );
  }
  hours(): number {
    return this.dCopy().hours();
  }
  minutes(): number {
    return this.dCopy().minutes();
  }
  hoursAndMinutes(): string {
    return (
      this.dCopy()
        .hours()
        .toString()
        .padStart(2, "0") +
      ":" +
      this.dCopy()
        .minutes()
        .toString()
        .padStart(2, "0")
    );
  }
  toMomentX(): MomentX {
    return new MomentX()
      .startOfDay()
      .withHours(this.dCopy().hours())
      .withMinutes(this.dCopy().minutes());
  }
}

declare namespace MomentX {
  type Moment = MomentX;
  export function parseZone(input?: string): MomentX;
  export function isMoment(input: any): boolean;
  export function minDate(): MomentX;
  export function maxDate(): MomentX;
  export function unix(input: number): MomentX;

  type Duration = DurationX;
  export function duration(input: string): DurationX;
  export function durationFromTime(hours: number, minutes: number): DurationX;
}

MomentX.parseZone = (input?: string) => {
  return new MomentX(momentBase.parseZone(input));
};
MomentX.isMoment = (input?: any) => {
  if (input !== undefined && input !== null && input.isMoment) {
    return true;
  } else {
    return false;
  }
};
MomentX.minDate = () => {
  return new MomentX(minDate);
};
MomentX.maxDate = () => {
  return new MomentX(maxDate);
};
MomentX.unix = (input: number) => {
  return new MomentX(momentBase.unix(input));
};

MomentX.duration = (input: string) => {
  return new DurationX(momentBase.duration(input));
};
MomentX.durationFromTime = (hours: number, minutes: number) => {
  return MomentX.duration(`0.${hours}:${minutes}:00.000`);
};

export default MomentX;
