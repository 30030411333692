

























































































































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import UsersTable from "@/components/support-admin/actor/user/UsersTable.vue";
import UserForm from "@/components/support-admin/actor/user/UserForm.vue";
import UserNotificationSettingsDialog from "@/components/support-admin/actor/user/UserNotificationSettingsDialog.vue";
import {
  SupportAdminActorListDto,
  ApiConfiguration,
  UserDto,
  UsersClient
} from "@/scripts/cld.api";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog, popupDialog } from "@/scripts/misc/popupDialogs";
import { actions } from "@/scripts/store/constants";
import { pushNotification } from "@/scripts/misc/notifications";

export default Vue.extend({
  components: {
    DialogCard,
    UsersTable,
    UserForm,
    UserNotificationSettingsDialog
  },
  props: {
    dialog: Boolean,
    actor: SupportAdminActorListDto
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.selectedLoadUserId = undefined;
          this.fetchUsers();
        }
      },
      immediate: true
    },
    user: {
      handler() {
        if (this.user) {
          //Validera direkt för att fel sedan längesen ska highlightas
          this.$nextTick(() => {
            let form: any = this.$refs.userForm;
            form.validate();
          });
        }
      },
      immediate: true
    }
  },
  computed: {
    title(): String {
      return `Användare - ${this.actor.actorName} (${this.actor.actorId})`;
    },
    user(): UserDto | undefined {
      return this.users.find(u => u.loadUserId === this.selectedLoadUserId);
    }
  },
  methods: {
    fetchUsers() {
      this.loading = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .byActorId(this.actor.actorId)
        .then(res => {
          this.users = res;
          if (!this.selectedLoadUserId) {
            this.selectedLoadUserId = this.users[0].loadUserId;
          }
          this.loading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    saveUser() {
      this.saveInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .edit(this.user!)
        .then(() => {
          pushNotification(
            this.user!.username + " har sparats",
            "mdi-check-circle"
          );
          this.saveInProgress = false;
          this.fetchUsers();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.saveInProgress = false;
              this.$emit("close");
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    clearConfigOnUser() {
      this.clearConfigInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .clearConfigurations(this.user!.loadUserId)
        .then(() => {
          pushNotification(
            this.user!.username + " har återställts till defaultinställningar.",
            "mdi-check-circle"
          );
          this.clearConfigInProgress = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.clearConfigInProgress = false;
              this.$emit("close");
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    toggleEnabled() {
      this.enableInProgress = true;
      const enable = !this.user!.isEnabled;
      new UsersClient(new ApiConfiguration(this.$store))
        .enable(this.user!.loadUserId, enable)
        .then(() => {
          pushNotification(
            this.user!.username +
              " har " +
              (enable ? "aktiverats" : "inaktiverats"),
            "mdi-check-circle"
          );
          this.enableInProgress = false;
          this.fetchUsers();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.$emit("close");
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    unlockUser() {
      this.unlockInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .resetLockout(this.user!.loadUserId)
        .then(() => {
          pushNotification(
            this.user!.username + " har låsts upp",
            "mdi-check-circle"
          );
          this.unlockInProgress = false;
          this.fetchUsers();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.$emit("close");
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    getResetCredentialsLink() {
      this.resetCredentialsInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .resetCredentialsLink(this.user!.username!)
        .then(res => {
          popupDialog({
            noCloseButton: true,
            title: "Lösenordslänk",
            body: res,
            btnText1: "OK",
            btnColor1: "success",
            btnCallback1: () => {}
          });
          this.resetCredentialsInProgress = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    loading: boolean;
    saveInProgress: boolean;
    clearConfigInProgress: boolean;
    unlockInProgress: boolean;
    enableInProgress: boolean;
    resetCredentialsInProgress: boolean;
    notificationDialog: boolean;
    valid: boolean;
    users: UserDto[];
    selectedLoadUserId: number | undefined;
  } => ({
    loading: true,
    saveInProgress: false,
    clearConfigInProgress: false,
    unlockInProgress: false,
    enableInProgress: false,
    resetCredentialsInProgress: false,
    notificationDialog: false,
    valid: false,
    users: [],
    selectedLoadUserId: undefined
  })
});
